import { useQuery, useMutation } from '@tanstack/react-query'
import noop from 'lodash/noop'
import { getBenchmarks, postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'
import { queryProcessor } from './deferredQueryProcessor'

const textSearchFields = ['displayName', 'shortName', 'longName']

export const mapSearch = (searchText) => searchText
  ? textSearchFields.reduce((prev, field) => {
    prev[field] = { op: 'contains', value: searchText }
    return prev
  }, {})
  : {}

export const useBenchmarks = (query, options = {}) => {
  const { onSuccess = noop, enabled = true } = options

  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getBenchmarks, userId, query],
    queryFn: async () => {
      const { data } = await getBenchmarks(query)
      return data
    },
    onSuccess,
    enabled
  })
}

export const useRecalcBenchmarkMutation = () => {
  const { firmId } = useAppContext()
  return useMutation({
    mutationFn: async (benchmarkId) => {
      return await getBenchmarks({
        method: 'reCalcBenchmarkValues',
        firmId: firmId,
        benchmarkId
      })
    }
  })
}

export const useSourceBenchmarks = (query, options = {}) => {
  const { onSuccess = noop, enabled = true, mapper } = options

  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getSourceBenchmarks, userId, query],
    queryFn: async () => {
      const { data } = await getBenchmarks(query)
      return data
    },
    onSuccess,
    enabled,
    select: mapper
  })
}

export const useGetBenchmarkValues = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: [QUERY_KEYS.getBenchmarksValues, userId, query],
    queryFn: async () => {
      return await queryProcessor(async q => await postNamedQuery('coreData', 'getBenchmarkValues', q), query)
    },
    enabled
  })
}

export const useCreateBlendedBench = (onSuccess) => {
  return useMutation({
    mutationFn: (payload) => {
      return getBenchmarks(payload)
    },
    onSuccess
  })
}
