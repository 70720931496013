import React from 'react'
import { Box } from '@material-ui/core'
import Text from '../../../components/atoms/Text'
import { useResearchViewContext } from './ResearchViewProvider'
import { Filters } from './Filters'
import ResearchLineChart from './lineChart/LineChart'
import AccountDetailTables from './tables/AccountDetailTables'
import AssetDetailTable from './tables/AssetDetailTable'
import ClientNameDisplay from './ClientNameDisplay'

export function ResearchContent () {
  const { levelFilters } = useResearchViewContext()

  return (
    <Box style={{ width: '100%' }}>
      <Box mb={4} display='flex' justifyContent='space-between'>
        <Text variant='h1'>
          Research Home
        </Text>
        <ClientNameDisplay />
      </Box>
      <Filters />
      <Box>
        <div>
          <ResearchLineChart />
        </div>
        <Box>
          <AssetDetailTable />
        </Box>

        {(!!levelFilters.accountIds?.length || !!levelFilters.assetIds?.length) && (
          <Box mt={4}>
            <AccountDetailTables />
          </Box>
        )}
      </Box>
    </Box>
  )
}
