import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  number: ({ value }) => value,
  // eslint-disable-next-line eqeqeq
  units: ({ value }) => value == 0 ? '--' : numeral(value).format('0,0.00'),
  money: ({ value }) => numeral(value).format('0,0.00'),
  percentage: ({ value }) => numeral(value).format('0.00%'),
  tags: ({ value }) => (
    <span>{value.map(x => (<span style={{ border: '1px solid gray', padding: '2px', marginRight: '2px' }} key={x.transactionCodeTagId}>{x.displayName}</span>))}</span>
  ),
  noWrap: ({ value }) => (
    <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
  )
}

const sharedAttrs = { alignRight: true, width: 50 }

export const defaultColumnConfig = {
  assetDetail: {
    columns: [
      { Header: 'Id', accessor: 'assetId', width: 30, format: 'default' },
      { Header: 'Name', accessor: 'levelDisplay', width: 100, format: 'title' },
      { Header: 'BMV', accessor: 'previousValue', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'PrevAccrual', accessor: 'previousAccrual', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Add', accessor: 'additions', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'With', accessor: 'withdrawals', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Gain', accessor: 'netChange', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'EMV', accessor: 'endingValue', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Accrual', accessor: 'accrual', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Income', accessor: 'income', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Fee', accessor: 'fee', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Return', accessor: 'returnValue', ...sharedAttrs, format: 'returns', sortType: compareNumericString },
      { Header: 'Trans', accessor: 'transactionCount', width: 30, format: 'number' },
      { Header: 'Status Id', accessor: 'maxStatusId', width: 30, format: 'number' }
    ],
    defaultSort: [{ id: 'endingValue', desc: true }]
  },
  accountDetail: {
    columns: [
      { Header: 'Date', accessor: 'maxDate', width: 30, format: '@M/D/YY' },
      {
        Header: 'Name',
        accessor: (row) => row.isAssetLevel ? row.assetName : row.accountName,
        width: 100,
        format: 'title'
      },
      { Header: 'BMV', accessor: 'previousValue', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'PrevAccrual', accessor: 'previousAccrual', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Add', accessor: 'additions', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'With', accessor: 'withdrawals', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Gain', accessor: 'netChange', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Income', accessor: 'income', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Fee', accessor: 'fee', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'EMV', accessor: 'endingValue', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Accrual', accessor: 'accrual', ...sharedAttrs, format: 'marketValueCents' },
      { Header: 'Return', accessor: 'returnValue', ...sharedAttrs, format: 'returns', sortType: compareNumericString },
      { Header: 'Return (C)', accessor: 'cumulativeReturn', ...sharedAttrs, format: 'returns', sortType: compareNumericString },
      { Header: 'Trans', accessor: 'transactionCount', width: 30, format: 'number' },
      { Header: 'Status Id', accessor: 'maxStatusId', width: 30, format: 'number' }
    ],
    defaultSort: [{ id: 'maxDate', desc: false }]
  }

}

function compareNumericString (rowA, rowB, desc = false) {
  let a = Number.parseFloat(rowA.values.returnValue)
  let b = Number.parseFloat(rowB.values.returnValue)

  if (Number.isNaN(a)) {
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

export const useColumns = ({
  dataType,
  formatter,
  columnConfig = defaultColumnConfig
}) => {
  return useMemo(() => {
    const { columns, defaultSort } = (columnConfig[dataType] || { columns: [] })
    const templatedColumns = columns.map(column => {
      const formatString = column.format

      return {
        ...column,
        Cell: ({ value }) => formatter(value, formatString)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [dataType, columnConfig, formatter])
}
