import React, { createContext, useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../redux/slices/appContext'
import { useAccountSearch } from '../../../api/coreData'
import { useSearchAssets } from '../../../api/groups'
import TearSheetDialog from './index'

const defaultContextValue = {
  getTearSheet: () => null,
  open: () => {}
}

const ClientTearSheetContext = createContext({ ...defaultContextValue })

export const useClientTearSheetManager = () => {
  return useContext(ClientTearSheetContext)
}

function ClientTearSheetDialogProvider ({ children }) {
  const { clientId } = useAppContext()
  const dialogRef = useRef(null)
  const accountQuery = useMemo(() => ({
    filters: {
      clientId,
      tearSheetId: [{ op: 'exists' }]
    },
    take: 1000
  }), [clientId])
  const assetQuery = useMemo(() => ({
    filters: {
      clientId,
      tearSheetId: [{ op: 'exists' }]
    },
    take: 1000
  }), [clientId])

  /* Here we are maintaining a list of possible accounts and assets with tear sheets for the client */
  const { data: accounts, isLoading: accountsLoading } = useAccountSearch(accountQuery)
  const { data: assets, isLoading: assetsLoading } = useSearchAssets(assetQuery)

  const value = useMemo(() => {
    if (accountsLoading || assetsLoading) {
      return { ...defaultContextValue }
    }

    /* used by <TearSheetLink> and <TearSheetOpenIcon> */
    const getTearSheet = (levelTypeId, levelId) => {
      if (levelTypeId === 1) {
        return (accounts?.data || []).find(x => x.accountId === levelId && x.tearSheetId)?.tearSheetId ?? null
      }
      if (levelTypeId === 3) {
        return (assets || []).find(x => x.assetId === levelId && x.tearSheetId)?.tearSheetId ?? null
      }
      return null
    }

    return {
      getTearSheet,
      open: (levelTypeId, levelId) => {
        const tearSheetId = getTearSheet(levelTypeId, levelId)
        if (!tearSheetId) return

        const openArgs = {
          levelTypeId,
          levelId,
          tearSheetId,
          bindings: {}
        }

        if (levelTypeId === 1) {
          openArgs.bindings.accountId = levelId
          openArgs.title = accounts.data.find(x => x.accountId === levelId)?.accountName
        } else if (levelTypeId === 3) {
          openArgs.bindings.assetId = levelId
          openArgs.title = accounts.data.find(x => x.assetId === levelId)?.assetName
        }
        return dialogRef.current.open(openArgs)
      }
    }
  }, [accountsLoading, assetsLoading, accounts, assets, dialogRef])

  return (
    <ClientTearSheetContext.Provider value={value}>
      {children}
      <TearSheetDialog ref={dialogRef} />
    </ClientTearSheetContext.Provider>
  )
}

ClientTearSheetDialogProvider.propTypes = {
  children: PropTypes.node
}

export default ClientTearSheetDialogProvider
