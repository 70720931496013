import React from 'react'
import { ResponsiveWrapper } from '@nivo/core'
import SimpleGauge from './SimpleGauge'

function ResponsiveSimpleGauge (props) {
  return (
    <ResponsiveWrapper>
      {({ width, height }) => (
        <SimpleGauge width={width} height={height} {...props} />
      )}
    </ResponsiveWrapper>
  )
}

ResponsiveSimpleGauge.propTypes = {
  ...SimpleGauge.propTypes
}

ResponsiveSimpleGauge.defaultProps = {
  ...SimpleGauge.defaultProps
}

export default ResponsiveSimpleGauge
