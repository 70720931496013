import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import PresentationTable from '../../PresentationTable'
import { useListDocumentsGroupedByTags } from '../../../../api/documentVault'
import { defaultViewSettings } from '../constants'
import { useStyles } from './styles'
import VaultEmptyState from './VaultEmptyState'

const VaultList = ({
  baseQueryParams,
  labels,
  onClick,
  columns,
  canUpload,
  hideIfEmpty = false,
  settings: _settings
}) => {
  const classes = useStyles()
  const [loadVersion, setLoadVersion] = useState(0)
  const queryParams = useMemo(() => baseQueryParams, [baseQueryParams])

  const settings = {
    ...defaultViewSettings.list,
    ..._settings
  }

  const { data, isFetching: isLoading } = useListDocumentsGroupedByTags(queryParams, {
    mapper: data => (data.tags ?? []).map(tag => ({
      ...tag,
      _next: true,
      subRows: tag.documents.map((d, i) => ({ ...d, name: d.document.name, _next: null, className: i % 2 === 0 ? 'even' : 'odd' }))
    })),
    onSuccess: () => setLoadVersion(v => v + 1),
    refetchOnMount: true
  })

  const isEmpty = useMemo(() => !isLoading && !data?.flatMap(t => t.documents)?.length, [isLoading, data])

  if (hideIfEmpty && (isLoading || isEmpty)) return null

  return (
    <>
      <div className={classes.tableContainer}>
        {!isLoading && isEmpty ? (
          <VaultEmptyState
            icon={labels.emptyState.icon}
            title={labels.emptyState.title}
            subtitle={labels.emptyState.subtitle}
            uploadStatement={canUpload ? labels.emptyState.uploadStatement : null}
          />
        ) : (
          <PresentationTable.Wrapper>
            <PresentationTable
              key={loadVersion}
              className={classes.vaultListTable}
              data={data ?? []}
              sortable
              loading={isLoading}
              columns={columns}
              expandable
              defaultExpanded={settings.defaultExpanded}
              onRowClick={(row, ...rest) => {
                onClick(row.original.document)
              }}
            />
          </PresentationTable.Wrapper>
        )}
      </div>
    </>
  )
}

VaultList.propTypes = {
  baseQueryParams: PropTypes.object,
  labels: PropTypes.object,
  onClick: PropTypes.func,
  columns: PropTypes.array,
  canUpload: PropTypes.bool,
  hideIfEmpty: PropTypes.bool,
  settings: PropTypes.objectOf({
    defaultExpanded: PropTypes.bool
  })
}

export default VaultList
