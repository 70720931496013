import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import ThingProvider from '../ThingProvider'
import SingleThing from '../SingleThing'
import ThingContentStyles from '../ThingContentStyles'

function ThingDialogForm ({ config }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ThingProvider levelTypeId={config.levelTypeId} levelId={config.levelId} showLoading>
          <ThingContentStyles
            jss={{
              padding: 0
            }}
          >
            <SingleThing hideIfEmpty={false} showHeader={false} variant='borderless' code={config.code} />
          </ThingContentStyles>
        </ThingProvider>
      </Grid>
    </Grid>
  )
}

ThingDialogForm.propTypes = {
  config: PropTypes.shape({
    levelTypeId: PropTypes.number,
    levelId: PropTypes.number,
    code: PropTypes.string
  })
}

export default ThingDialogForm
