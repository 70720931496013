import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  useAddComment,
  useCommentThreadDetails,
  useCreateCommentThread,
  useEditComment,
  useRemoveComment
} from '../../../api/comments'
import {
  useAddMultipleAttachments,
  useAssignedAttachmentsByClient,
  useRemoveAttachment
} from '../../../api/documentVault'
import { useAppContext } from '../../../redux/slices/appContext'

const CommentContext = createContext({
  threadKey: null
})

export const useComments = () => {
  return useContext(CommentContext)
}

function CommentContextProvider ({ children }) {
  const { clientId } = useAppContext()
  const [state, setState] = useState({
    open: false,
    tab: 'comments',
    clientId: clientId
  })
  const toggleOpen = useCallback(() => {
    setState(prev => ({
      ...prev,
      open: !prev.open
    }))
  }, [setState])

  const setTab = useCallback((tab) => {
    setState(prev => ({
      ...prev,
      tab
    }))
  }, [setState])

  const showCommentThread = useCallback(({ threadKey, title, ...otherState }) => {
    setState(prev => ({
      open: 'comments',
      tab: 'comments',
      title,
      threadKey,
      ...otherState
    }))
  }, [setState])

  const showAttachments = useCallback(({ attachmentInfo, title, ...otherState }) => {
    setState(prev => ({
      open: 'comments',
      tab: 'attachments',
      title,
      ...otherState
    }))
  }, [setState])

  const showAttachmentsOnly = useCallback(({ attachmentInfo, title, ...otherState }) => {
    setState(prev => ({
      open: 'attachments-only',
      title,
      ...otherState
    }))
  }, [setState])

  /** Use this if you are wanting to open the comment panel in the state of a new thread */
  const createCommentThread = useCallback((title, otherState = {}) => {
    setState(prev => ({
      open: 'comments',
      tab: 'comments',
      title,
      threadKey: 'new',
      ...otherState
    }))
  }, [setState])

  /** Use this version from inside of the comment panel */
  const setNewThread = useCallback(() => {
    setState(prev => ({
      ...prev,
      threadKey: 'new'
    }))
  }, [setState])

  const { data, isLoading, error, refetch } = useCommentThreadDetails(state.threadKey, state.levelTypeId, state.levelId, state.clientId)
  const { data: attachments, isLoading: attachmentsLoading, refetch: refetchAttachments } = useAssignedAttachmentsByClient(state.levelTypeId, state.levelId, state.clientId)
  const { mutateAsync: submitNewCommentThread } = useCreateCommentThread()
  const { mutateAsync: addCommentAsync } = useAddComment()
  const { mutateAsync: editCommentAsync } = useEditComment()
  const { mutateAsync: removeCommentAsync } = useRemoveComment()
  const { mutateAsync: addAttachmentsAsync } = useAddMultipleAttachments()
  const { mutateAsync: removeAttachmentAsync } = useRemoveAttachment()

  const finishCreatingThread = useCallback(async (body) => {
    const commandBody = {
      clientId: state.clientId,
      levelTypeId: state.levelTypeId,
      levelId: state.levelId,
      body
    }
    const result = await submitNewCommentThread(commandBody)
    setState(prev => ({ ...prev, threadKey: result.threadKey }))
    if (state.onRefetch) {
      state.onRefetch()
    }
  }, [state, setState, submitNewCommentThread])

  const deleteComment = useCallback(async (comment) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Are you sure you want to delete this comment?')
    if (!confirmed) {
      return
    }
    const deleteResult = await removeCommentAsync({
      commentKey: comment.commentKey,
      threadKey: data.thread.threadKey
    })
    if (deleteResult.threadDeleted) {
      if (state.onRefetch) {
        state.onRefetch()
      }

      setState(prev => ({
        ...prev,
        open: false,
        editing: null
      }))
    } else {
      refetch().catch(console.error)
      setState(prev => ({
        ...prev,
        editing: null
      }))
    }
  }, [data, removeCommentAsync, setState, refetch, state])

  const editComment = useCallback((comment) => {
    setState(prev => ({
      ...prev,
      editing: comment
    }))
  }, [setState])
  const finishEditing = useCallback(async (comment, body) => {
    await editCommentAsync({
      commentKey: comment.commentKey,
      threadKey: data.thread.threadKey,
      body
    })
    refetch().catch(console.error)
    setState(prev => ({
      ...prev,
      editing: null
    }))
  }, [data, editCommentAsync, setState, refetch])
  const stopEditing = useCallback(() => {
    setState(prev => ({
      ...prev,
      editing: null
    }))
  }, [setState])

  // adding
  const addComment = useCallback((comment) => {
    setState(prev => ({
      ...prev,
      editing: {
        commentKey: 'new',
        body: ''
      }
    }))
  }, [setState])
  const stopAdding = useCallback(() => {
    setState(prev => ({
      ...prev,
      editing: null
    }))
  }, [setState])
  const finishAdding = useCallback(async (body) => {
    await addCommentAsync({
      threadKey: data.thread.threadKey,
      body
    })
    refetch().catch(console.error)
    setState(prev => ({
      ...prev,
      editing: null
    }))
  }, [data, addCommentAsync, setState, refetch])
  const addAttachment = useCallback(() => {
    setState(prev => ({
      ...prev,
      attaching: 'add'
    }))
  }, [setState])
  const linkAttachment = useCallback(() => {
    setState(prev => ({
      ...prev,
      attaching: 'link'
    }))
  }, [setState])
  const stopAttaching = useCallback(() => {
    setState(prev => ({ ...prev, attaching: null }))
  }, [setState])
  const finishAttaching = useCallback(async (attachmentInfo) => {
    await addAttachmentsAsync({ ...attachmentInfo, documents: attachmentInfo.documents })
    setState(prev => ({
      ...prev,
      attaching: null
    }))
    refetchAttachments().catch(console.error)
    if (state.onRefetch) {
      state.onRefetch()
    }
  }, [addAttachmentsAsync, refetchAttachments, state])
  const removeAttachment = useCallback(async (attachment) => {
    await removeAttachmentAsync(attachment)
    refetchAttachments().catch(console.error)
  }, [removeAttachmentAsync, refetchAttachments])

  const attachmentDetails = useMemo(() => ({
    attachments: attachments,
    isLoading: attachmentsLoading,
    refetch: refetchAttachments
  }), [attachments, attachmentsLoading, refetchAttachments])

  const value = useMemo(() => {
    return {
      clientId: state.clientId,
      levelTypeId: state.levelTypeId,
      levelId: state.levelId,
      title: state.title ?? 'Comments',
      titleProps: state.titleProps ?? {},
      tab: state.tab ?? 'comments',
      open: state.open,
      threadKey: state.threadKey || data?.thread?.threadKey,
      editing: state.editing,
      attaching: state.attaching,
      threadDetails: {
        data,
        isLoading,
        error,
        refetch
      }
    }
  }, [
    state,
    data,
    isLoading,
    error,
    refetch
  ])

  const result = {
    ...value,
    setTab,
    editComment,
    stopEditing,
    finishEditing,
    addComment,
    stopAdding,
    finishAdding,
    deleteComment,
    showCommentThread,
    createCommentThread,
    setNewThread,
    finishCreatingThread,
    toggleOpen,
    showAttachments,
    showAttachmentsOnly,
    attachmentDetails,
    addAttachment,
    linkAttachment,
    stopAttaching,
    finishAttaching,
    removeAttachment
  }

  return (
    <CommentContext.Provider value={result}>
      {children}
    </CommentContext.Provider>
  )
}

CommentContextProvider.propTypes = {
  children: PropTypes.node
}

export default CommentContextProvider
