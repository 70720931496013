import React from 'react'
import PropTypes from 'prop-types'
import RangedKeyDatapointGauge from './RangedKeyDatapointGauge'
import SimpleKeyDatapointGauge from './SimpleKeyDatapointGauge'

function KeyDatapointGauge ({
  variant,
  ...otherProps
}) {
  if (variant === 'ranged') {
    return (
      <RangedKeyDatapointGauge {...otherProps} />
    )
  }

  return (
    <SimpleKeyDatapointGauge {...otherProps} />
  )
}

KeyDatapointGauge.propTypes = {
  variant: PropTypes.oneOf(['ranged', 'simple'])
}

KeyDatapointGauge.defaultProps = {
  variant: 'ranged'
}

export default KeyDatapointGauge
