import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import ResponsiveRangedGauge from '../../nivo/RangedGauge/ResponsiveRangedGauge'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Skeleton from '../../atoms/Skeleton'
import { useRangedGaugeData } from './useRangedGaugeData'

const useStyles = makeStyles((theme) => ({
  keyDatapointGauge: {
    height: '100%',
    '&.__empty': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '22px'
    },

    '& svg text': {
      color: ({ textColor }) => textColor,
      fill: ({ textColor }) => textColor
    }
  }
}))

function RangedKeyDatapointGauge ({
  style,
  className,
  groupTypeId,
  keyDatapoint,
  noDataMessage,
  textColor,
  ...otherProps
}) {
  const classes = useStyles({ textColor })
  const { marker, ranges, isLoading } = useRangedGaugeData(groupTypeId, keyDatapoint, otherProps)

  if (isLoading) {
    return (
      <FadeIn duration={5} delay={5} style={style} className={clsx(className, classes.keyDatapointGauge)}>
        <Skeleton width='100%' height='100%' />
      </FadeIn>
    )
  }

  if (!marker || !ranges) {
    return (
      <FadeIn style={style} className={clsx(className, classes.keyDatapointGauge, '__empty')}>
        <div>{noDataMessage}</div>
      </FadeIn>
    )
  }

  return (
    <FadeIn duration={1} style={style} className={clsx(className, classes.keyDatapointGauge)}>
      <ResponsiveRangedGauge
        marker={marker}
        ranges={ranges}
        {...otherProps}
      />
    </FadeIn>
  )
}

RangedKeyDatapointGauge.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string,
  groupTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyDatapoint: PropTypes.string,
  markerColor: PropTypes.string,
  textColor: PropTypes.string,
  markerRadius: PropTypes.number,
  noDataMessage: PropTypes.string,
  valueFormat: PropTypes.string
}

RangedKeyDatapointGauge.defaultProps = {
  markerColor: 'red',
  markerRadius: 20,
  noDataMessage: 'No Data Available',
  valueFormat: 'percentage',
  variant: 'ranged'
}

export default RangedKeyDatapointGauge
