const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const jitter = () => Math.floor(Math.random() * 50)
const checkFeature = name => window._config?.featureFlags?.find?.(x => x.name === name)?.active === true

export const queryProcessor = async (fn, body) => {
  // Allow opting out of long timeouts (this is the default)
  if (!checkFeature('DEFERRED_QUERYING')) {
    const response = await fn(body)
    return response.data
  }

  const meta = { cacheTtl: 30, sequenceNumber: 0 }
  const getRequest = () => {
    return { ...body, meta: { ...meta, sequenceNumber: meta.sequenceNumber++ } }
  }

  const response = await fn(getRequest())
  let result = response.data
  let keepQuerying = !result.result
  let timeout = 500
  while (keepQuerying) {
    await sleep(timeout)
    timeout = Math.min(10000, (timeout * 1.5)) + jitter()

    const res = await fn(getRequest())
    result = res.data
    keepQuerying = result.status === 'processing'
  }

  if (result.status === 'error') {
    throw new Error(result.result)
  }
  if (result.status === 'timeout') {
    throw new Error('Query timed out')
  }

  return result.result
}
