import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import { useSearchAccounts } from '../../../api/accounts'

dayjs.extend(utc)

const ResearchViewContext = createContext({
  children: null,
  filters: {}
})
const ResearchViewProvider = (
  {
    children,
    endDate,
    month = 6,
    clientId
  }) => {
  const [selectedMonth, setSelectedMonth] = useState(month)
  const [dateRange, setDateRange] = useState({
    startDate: dayjs.utc(endDate).subtract(selectedMonth, 'month').format('YYYY-MM-DD'),
    endDate: dayjs.utc(endDate).format('YYYY-MM-DD')
  })

  const levelFilterBase = useMemo(() => ({
    dateType: 'day',
    clientIds: [
      clientId
    ],
    levelTypes: [
      'client',
      'account'
    ],
    calcType: 'performance'
  }), [clientId])

  const [levelFilters, setLevelFilters] = useState(levelFilterBase)
  useEffect(() => {
    if (levelFilters.clientIds !== levelFilterBase.clientIds) {
      setLevelFilters(levelFilterBase)
      setDateRange({
        startDate: dayjs.utc(endDate).subtract(selectedMonth, 'month').format('YYYY-MM-DD'),
        endDate: dayjs.utc(endDate).format('YYYY-MM-DD')
      })
    }
  }, [clientId, endDate, levelFilterBase, levelFilters.clientIds, selectedMonth])

  const { data: accounts } = useSearchAccounts({
    filters: { assignedToClientIds: [clientId] },
    take: 999
  })

  const accountsMap = useMemo(() => {
    return accounts?.data?.reduce((acc, account) => {
      acc[account.accountId] = account
      return acc
    }, {})
  }, [accounts])

  const value = useMemo(
    () => ({
      clientId,
      dateRange,
      setDateRange,
      selectedMonth,
      setSelectedMonth,
      levelFilters,
      setLevelFilters,
      accountsMap
    }),
    [clientId, dateRange, selectedMonth, levelFilters, accountsMap]
  )

  return (
    <ResearchViewContext.Provider value={value}>
      {children}
    </ResearchViewContext.Provider>
  )
}

export const useResearchViewContext = () => {
  return useContext(ResearchViewContext)
}

ResearchViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
  endDate: PropTypes.string,
  month: PropTypes.number,
  clientId: PropTypes.number
}

export default ResearchViewProvider
