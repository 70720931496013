import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import { useGetGroupedCoreData } from '../../../api/coreData'
import { CALC_TYPES, TEXT_VARIANTS } from '../../../constants'
import Text from '../../atoms/Text'
import Skeleton from '../../atoms/Skeleton'
import { AccountCard } from './AccountCard'

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    display: 'flex',
    gridGap: '8px',
    flexWrap: 'wrap'
  },
  groupsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  }
}))
const AccountCardCollection = ({
  title = '',
  query,
  grouping = { key: 'accountCategoryGroupId', label: 'accountCategoryLongName' },
  styles = { padding: '2rem' },
  imageSettings = {
    show: true,
    backgroundColor: '#dddddd'
  },
  formats: _formats
}) => {
  const classes = useStyles()
  const { clientId, availableDates } = useAppContext()

  const formats = useMemo(() => ({
    endingValue: '0,000.00',
    ..._formats
  }), [_formats])

  const { data: accounts, isLoading } = useGetGroupedCoreData({
    ...query,
    dateRange: {
      startDate: availableDates.mainDate,
      endDate: availableDates.mainDate
    },
    levelFilters: {
      levelTypes: ['client', 'accountCategory', 'account'],
      ...query.levelFilters,
      clientIds: [clientId],
      calcType: CALC_TYPES.balance,
      additionalData: {
        custodians: ['name', 'imageUrl']
      }
    }
  }, {
    enabled: !!clientId && !!availableDates.mainDate
  })

  const groupedData = useMemo(() => {
    if (!accounts) return []
    if (!grouping) return accounts

    return accounts.reduce((acc, account) => {
      const key = account[grouping.key]
      const existingGroup = acc.find(group => group.key === key)

      if (existingGroup) {
        existingGroup.accounts.push(account)
      } else {
        acc.push({
          key,
          label: account[grouping.label],
          accounts: [account]
        })
      }
      return acc
    }, [])
  }, [accounts, grouping])

  return (
    <div style={styles}>
      {!!title?.length && (<Text variant={TEXT_VARIANTS.h1}>{title}</Text>)}
      <div className={classes.groupsContainer}>
        {isLoading && (
          <div>
            <Skeleton variant='text' height='45px' width='200px' />
            <div className={classes.accountsContainer}>
              {Array.from({ length: 4 }).map((_, i) => (
                <AccountCard
                  key={i}
                  imageSettings={imageSettings}
                  isLoading
                />
              ))}
            </div>
          </div>
        )}
        {groupedData?.map(group => (
          <div key={group.key}>
            {group.label && (<Text variant={TEXT_VARIANTS.h2}>{group.label}</Text>)}
            <div className={classes.accountsContainer}>
              {group.accounts.map(account => (
                <AccountCard
                  key={account.uniqueId}
                  account={account}
                  imageSettings={imageSettings}
                  formats={formats}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
AccountCardCollection.propTypes = {
  title: PropTypes.string,
  query: PropTypes.object,
  grouping: PropTypes.object,
  styles: PropTypes.object,
  imageSettings: PropTypes.object,
  formats: PropTypes.object
}

export default AccountCardCollection
