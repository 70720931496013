/* eslint-disable no-unused-vars */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DefaultLayout from '../../../abundanceEngine/layouts/DefaultLayout'
import { useAppContext } from '../../../redux/slices/appContext'
import ResearchViewProvider from './ResearchViewProvider'
import { ResearchContent } from './ResearchContent'

const useStyles = makeStyles({
  root: {
    width: 250
  },
  inputTop: {
    fontSize: '24px',
    fontWeight: 700,
    width: '160px',
    paddingBottom: '0px'
  },
  goalsHeader: {
    margin: '24px 0px 24px 0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerWrapper: {
    margin: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterWrapper: {
    margin: '6px',
    display: 'flex'
  },
  filterItem: {
    margin: '6px',
    padding: '6px 12px',
    border: '2px solid #212945',
    backgroundColor: '#212945',
    color: '#FFFFFF',
    '&:hover': {
      color: '#212945',
      backgroundColor: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  dateWrapper: {
    margin: '6px',
    display: 'flex'
  },
  dateChangeItem: {
    padding: '0px 12px',
    color: '#3399FF',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  monthChangeItem: {
    padding: '0px 4px',
    color: '#3399FF',
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

const ResearchHome = () => {
  const classes = useStyles()

  const { clientId, availableDates } = useAppContext()

  if (!clientId) {
    return null
  }

  return (
    <ResearchViewProvider endDate={availableDates.max} clientId={clientId}>
      <DefaultLayout>
        <ResearchContent />
      </DefaultLayout>
    </ResearchViewProvider>
  )
}

export default ResearchHome
