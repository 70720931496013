import PropTypes from 'prop-types'

export const operationalTablePropTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  defaultPageSize: PropTypes.number,
  defaultSort: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  ),
  expandable: PropTypes.bool,
  itemName: PropTypes.string,
  loading: PropTypes.bool,
  searchText: PropTypes.string,
  total: PropTypes.number,
  getSubRow: PropTypes.func,
  onSortingChange: PropTypes.func,
  onPagingChange: PropTypes.func,
  onExpandChange: PropTypes.func,
  onRowClick: PropTypes.func,
  hideFooter: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  autoSticky: PropTypes.bool,
  footNote: PropTypes.string,
  variant: PropTypes.oneOf(['v1', 'v2', 'blank'])
}
