import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useListDocuments } from '../../../../api/documentVault'
import VaultCard from '../VaultCard'
import FileEditModal from '../FileEditModal'
import { defaultViewSettings } from '../constants'
import { useStyles } from './styles'
import VaultEmptyState from './VaultEmptyState'

const VaultCardGrid = ({
  baseQueryParams,
  labels,
  showTags,
  view,
  onClick,
  canUpload,
  maxLoadingCount = 10,
  isCollapsed = false,
  onLoaded = noop,
  hideIfEmpty = false,
  settings: _settings
}) => {
  const classes = useStyles()
  const queryParams = useMemo(() => baseQueryParams, [baseQueryParams])
  const loadingCount = useMemo(() => Math.min(baseQueryParams.take, maxLoadingCount), [baseQueryParams.take, maxLoadingCount])
  const [editingDocument, setEditingDocument] = useState(null)

  const settings = {
    ...defaultViewSettings.grid,
    ..._settings
  }

  const { data, isFetching: isLoading, refetch } = useListDocuments(queryParams, {
    mapper: data => data.documents ?? [],
    onSuccess: onLoaded,
    refetchOnMount: true
  })

  const isEmpty = useMemo(() => !isLoading && !data?.flatMap(t => t.documents)?.length, [isLoading, data])

  if (hideIfEmpty && (isLoading || isEmpty)) return null

  return (
    <>
      {isLoading ? (
        <>
          <div
            className={clsx(classes.gridContainer, {
              horizontalScroll: view === 'grid_horizontal',
              collapsed: isCollapsed
            })}
          >
            {Array.from({ length: loadingCount }).map((_x, i) => <VaultCard key={`placeholder_${i}`} isLoading />)}
          </div>
        </>
      ) : (!data?.length ? (
        <VaultEmptyState
          icon={labels.emptyState?.icon}
          title={labels.emptyState?.title}
          subtitle={labels.emptyState?.subtitle}
          uploadStatement={canUpload ? labels.emptyState.uploadStatement : null}
        />
      ) : (
        <div
          className={clsx(classes.gridContainer, {
            horizontalScroll: view === 'grid_horizontal',
            collapsed: isCollapsed
          })}
        >
          {data?.map((document) => (
            <VaultCard
              key={document?.document?.documentId}
              document={document}
              onClick={onClick}
              showTags={showTags}
              labels={labels}
              onEditDocument={(doc) => setEditingDocument(doc)}
              downloadContentDisposition={settings.downloadContentDisposition}
            />
          ))}
        </div>
      ))}

      {editingDocument && (
        <>
          <FileEditModal
            document={editingDocument}
            labels={labels?.visibility}
            onClose={() => setEditingDocument(null)}
            onFinish={async () => {
              await refetch()
              setEditingDocument(null)
            }}
          />
        </>
      )}
    </>
  )
}

VaultCardGrid.propTypes = {
  baseQueryParams: PropTypes.object,
  showTags: PropTypes.bool,
  labels: PropTypes.object,
  view: PropTypes.oneOf(['grid', 'grid_horizontal']),
  onClick: PropTypes.func,
  canUpload: PropTypes.bool,
  maxLoadingCount: PropTypes.number,
  isCollapsed: PropTypes.bool,
  onLoaded: PropTypes.func,
  hideIfEmpty: PropTypes.bool,
  settings: PropTypes.objectOf({
    downloadContentDisposition: PropTypes.oneOf(['inline', 'attachment'])
  })
}

export default VaultCardGrid
