import React, { createContext, useContext, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import { ICON_NAMES } from '../../../constants'
import { useNormalizeLevelValues } from './hooks'
import {
  defaultCollectionsLabel,
  defaultCreateDocumentLabel,
  defaultDocumentDetailsLabel,
  defaultTogglesLabel,
  defaultViewSettings,
  defaultVisibilityLabel
} from './constants'

dayjs.extend(utc)

const VaultContext = createContext({
  children: null,
  filters: {}
})
const VaultContextProvider = (
  {
    children,
    levels: _levels,
    filters: _filters,
    availableViews,
    labels,
    uploadLevelTypeIds,
    defaultVisibility,
    showUploadButton,
    detailItems,
    columns,
    groupVaultByTags,
    includeRecentGroup,
    viewSettings
  }) => {
  const levels = useNormalizeLevelValues(_levels)

  const [filters, setFilters] = useState(_filters)
  const [view, setView] = useState(availableViews[0].key ?? 'grid')
  const [currentTab, setCurrentTab] = useState(levels?.[0]?.levelTypeId?.toString() ?? null)

  const canUpload = useMemo(() => {
    const currentTabLevel = levels.find(level => level.levelTypeId.toString() === currentTab)
    return uploadLevelTypeIds.includes(currentTabLevel.levelTypeId) && currentTabLevel.levelIds?.length === 1
  }, [currentTab, levels, uploadLevelTypeIds])

  const value = useMemo(
    () => ({
      currentTab,
      setCurrentTab,
      filters,
      setFilters,
      levels,
      availableViews,
      view,
      setView,
      labels,
      uploadLevelTypeIds,
      defaultVisibility,
      canUpload,
      showUploadButton,
      detailItems,
      columns,
      groupVaultByTags,
      includeRecentGroup,
      viewSettings
    }),
    [
      currentTab,
      filters,
      levels,
      availableViews,
      view,
      labels,
      uploadLevelTypeIds,
      defaultVisibility,
      canUpload,
      showUploadButton,
      detailItems,
      columns,
      groupVaultByTags,
      includeRecentGroup,
      viewSettings
    ]
  )

  return (
    <VaultContext.Provider value={value}>
      {children}
    </VaultContext.Provider>
  )
}

export const useVaultContext = () => {
  return useContext(VaultContext)
}

VaultContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  filters: PropTypes.object,
  levels: PropTypes.arrayOf(PropTypes.shape({
    levelTypeId: PropTypes.number,
    levelIds: PropTypes.arrayOf(PropTypes.number)
  })),
  availableViews: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string, description: PropTypes.string })),
  viewSettings: PropTypes.objectOf({
    list: PropTypes.objectOf({
      defaultExpanded: PropTypes.bool
    }),
    grid: PropTypes.objectOf({
      downloadContentDisposition: PropTypes.oneOf(['inline', 'attachment'])
    })
  }),
  labels: PropTypes.shape({ titles: PropTypes.object }),
  uploadLevelTypeIds: PropTypes.arrayOf(PropTypes.number),
  defaultVisibility: PropTypes.arrayOf(PropTypes.string),
  showUploadButton: PropTypes.bool,
  detailItems: PropTypes.array,
  columns: PropTypes.array,
  groupVaultByTags: PropTypes.object,
  includeRecentGroup: PropTypes.object
}

VaultContextProvider.defaultProps = {
  children: null,
  filters: {},
  levels: [{ levelTypeId: 1000000 }, { levelTypeId: 201 }],
  availableViews: [
    {
      key: 'grid',
      label: 'Grid',
      icon: ICON_NAMES.document,
      description: 'Grid view'
    },
    {
      key: 'list',
      label: 'List',
      icon: ICON_NAMES.listAlt,
      description: 'List view'
    }
  ],
  viewSettings: defaultViewSettings,
  labels: {
    titles: {
      1000000: 'Firm Documents',
      201: 'Your Documents'
    },
    emptyState: {
      icon: ICON_NAMES.paperMilestones,
      title: 'No documents found',
      subtitle: 'There are no documents to display',
      uploadStatement: 'Drag a document here to upload it'
    },
    collections: defaultCollectionsLabel,
    toggles: defaultTogglesLabel,
    addButton: defaultCreateDocumentLabel,
    documentDetails: defaultDocumentDetailsLabel,
    visibility: defaultVisibilityLabel
  },
  uploadLevelTypeIds: [201],
  defaultVisibility: ['visible', 'internal', 'hidden'],
  showUploadButton: true,
  detailItems: ['title', 'downloadButton', 'deleteButton', 'createdByUser', 'tags', 'description', 'visibility'],
  columns: ['name', 'visibility', 'tags', 'download', 'edit'],
  groupVaultByTags: {
    1000000: false,
    201: true
  },
  includeRecentGroup: {
    1000000: true,
    201: true
  }
}

export default VaultContextProvider
