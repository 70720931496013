import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useClientTearSheetManager } from './ClientTearSheetDialogProvider'

const useStyles = makeStyles(theme => ({
  tearSheetLink: {
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.weights.bold
    }
  }
}))

function TearSheetLink ({ children, className, style, levelTypeId, levelId }) {
  const classes = useStyles()
  const tearSheetManager = useClientTearSheetManager()
  if (tearSheetManager.getTearSheet(levelTypeId, levelId)) {
    return (
      <div
        role='button' className={clsx(classes.tearSheetLink, className)} style={style} onClick={(e) => {
          e.stopPropagation()
          return tearSheetManager.open(levelTypeId, levelId)
        }}
      >
        {children}
      </div>
    )
  }

  return children
}

TearSheetLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  levelTypeId: PropTypes.number.isRequired,
  levelId: PropTypes.number.isRequired
}

export default TearSheetLink
