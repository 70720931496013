import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import noop from 'lodash/noop'
import Skeleton from '../../../atoms/Skeleton'
import { ICON_NAMES } from '../../../../constants'
import { useDownloadDocument } from '../hooks'
import { useVaultContext } from '../VaultContextProvider'
import { useInternalUserId } from '../../../../redux/slices/appContext'
import { useCheckPolicy } from '../../../../hooks'
import DocumentThumbnail from './DocumentThumbnail'
import VisibilityIcon from './VisibilityIcon'

const VaultCard = ({
  document = null,
  isLoading = false,
  onClick = noop,
  showTags = true,
  showVisibilityIcon: _showVisibilityIcon = true,
  labels,
  onEditDocument,
  downloadContentDisposition = 'inline'
}) => {
  const internalUserId = useInternalUserId()
  const { labels: contextLabels } = useVaultContext()
  const { isLoadingDownload, getDownload } = useDownloadDocument(document?.document?.documentId, downloadContentDisposition)
  const userCanEditAnyDocument = useCheckPolicy('vault_edit_any_document')

  const menuOptions = useMemo(() => ([
    {
      iconName: ICON_NAMES.download,
      label: isLoadingDownload ? 'Loading...' : 'Download',
      onClick: () => getDownload(),
      levelTypeIds: [1000000, 201, 1, 4]
    },
    {
      iconName: ICON_NAMES.edit,
      label: 'Edit',
      onClick: () => onEditDocument(document),
      levelTypeIds: [201],
      userEnabled: (document) => document.document.createdByUser?.userId === internalUserId || userCanEditAnyDocument
    }
  ]), [isLoadingDownload, getDownload, onEditDocument, document, internalUserId, userCanEditAnyDocument])

  const getDocumentThumbnailOptions = useCallback((document) => (
    menuOptions.filter((option) => option.userEnabled ? option.userEnabled(document) : true)
  ), [menuOptions])

  const showVisibilityIcon = useMemo(() => !!(_showVisibilityIcon && document?.document?.visibility && document?.document?.visibility !== 'visible'), [_showVisibilityIcon, document?.document?.visibility])

  const visibilityLabel = useMemo(() => labels?.visibilityLabels ?? contextLabels?.visibilityLabels, [contextLabels?.visibilityLabels, labels?.visibilityLabels])

  return (
    <Box flexBasis='230px' flexShrink={0} position='relative'>
      {
        isLoading
          ? (
            <Box height='226px' width='100%' flex={1}>
              <Skeleton height='100%' />
            </Box>
          ) : (
            <DocumentThumbnail
              onClick={() => onClick(document.document)}
              document={document.document}
              src={document.thumbnailUrl}
              menuOptions={getDocumentThumbnailOptions(document)}
              showTags={showTags}
            />
          )
      }
      {showVisibilityIcon && (
        <>
          <VisibilityIcon
            visibility={document.document.visibility}
            labels={visibilityLabel}
          />
        </>
      )}
    </Box>
  )
}

VaultCard.propTypes = {
  document: PropTypes.object,
  isLoading: PropTypes.bool,
  labels: PropTypes.shape({
    visibilityLabels: PropTypes.object
  }),
  showVisibilityIcon: PropTypes.bool,
  onClick: PropTypes.func,
  showTags: PropTypes.bool,
  onEditDocument: PropTypes.func,
  downloadContentDisposition: PropTypes.oneOf(['inline', 'attachment'])
}

export default VaultCard
