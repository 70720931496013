import ThingProvider from './ThingProvider'
import ThingDebug from './ThingDebug'
import ThingSection from './ThingSection'
import SingleThing from './SingleThing'
import ThingContentStyles from './ThingContentStyles'
import ThingLoader from './ThingLoader'

const thingModule = {
  SingleThing,
  ThingContentStyles,
  ThingProvider,
  ThingDebug,
  ThingSection,
  ThingLoader
}

export default thingModule
