import React from 'react'
import PropTypes from 'prop-types'
import Loading from '../../molecules/Loading'
import { useThingContext } from './ThingContext'

const ThingLoader = ({ children }) => {
  const { isLoading } = useThingContext()

  if (isLoading) {
    return <Loading />
  }

  return children
}

ThingLoader.propTypes = {
  children: PropTypes.node
}

export default ThingLoader
