import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../constants'
import NumberFormat from '../../atoms/NumberFormat'
import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import Skeleton from '../../atoms/Skeleton'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingTop: '2rem'
  },
  divider: {
    width: '100%',
    backgroundImage: 'repeating-linear-gradient(90deg, #dedede, #dedede 1.25rem, transparent 1.25rem, transparent 2.5rem)',
    height: '3px'
  },
  title: {
    marginTop: '2rem'
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'stretch',
    padding: '2rem 0',
    gap: '8px',
    flexWrap: 'wrap',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: '0.5rem',
    flex: '0 0 calc((100% - (3 * 8px)) / 4)',
    minWidth: '250px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: '3rem 1rem',
    [theme.breakpoints.down('md')]: {
      flex: '0 0 calc((100% - (2 * 8px)) / 3)'
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 calc((100% - 8px) / 2)'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  },
  cardLoadingState: {
    padding: 0,
    overflow: 'hidden'
  },
  cardImage: ({ backgroundColor, backgroundImage }) => ({
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    backgroundSize: backgroundImage?.indexOf('.svg') >= 0 ? '2.25rem' : 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: theme.palette.getContrastText(backgroundColor),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  }),
  cardContent: {
    textAlign: 'center'
  }
}))

export const AccountCard = ({ isLoading = false, account, imageSettings, formats }) => {
  const { backgroundColor = '#aaaaaa', show: showImage = true } = imageSettings ?? {}
  const classes = useStyles({ backgroundColor, backgroundImage: account?.custodianImageUrl })
  const { formatter } = useFormattingContext()
  const icon = ICON_NAMES.file

  if (isLoading) {
    return (
      <div className={clsx(classes.card, isLoading && classes.cardLoadingState)}>
        <Skeleton height={showImage ? '220px' : '160px'} width='100%' />
      </div>
    )
  }

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        {showImage && (
          <div className={classes.cardImage}>
            {!account.custodianImageUrl?.length && (<Icon name={icon} variant='line' customSize='2rem' />)}
          </div>
        )}
      </div>
      <Text variant={TEXT_VARIANTS.h1} customFontSize='1.25rem'>
        <NumberFormat
          title={formatter(account.endingValue, formats.endingValue)} number={formatter(account.endingValue, formats.endingValue)}
          skipFormat
        />
      </Text>
      <Text variant={TEXT_VARIANTS.body} customFontSize='0.875rem' text={account.accountName} />
    </div>
  )
}
AccountCard.propTypes = {
  isLoading: PropTypes.bool,
  account: PropTypes.object,
  formats: PropTypes.object,
  imageSettings: PropTypes.object
}
