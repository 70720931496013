import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '6px',
    marginBottom: '2px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200]
  },
  label: {
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 6px',
    borderRadius: '4px',
    marginLeft: '4px',
    fontSize: '.8em'
  },
  collapseButton: {
    '& svg': {
      transition: 'all 0.3s',
      rotate: '180deg'
    }
  },
  collapseButtonActive: {
    '& svg': {
      transition: 'all 0.3s',
      rotate: '0deg'
    }
  },
  collapsedTable: {
    height: 0,
    overflow: 'hidden'
  }
}))
export default useStyles
