import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import isEmpty from 'lodash/isEmpty'
import Skeleton from '../../atoms/Skeleton'
import EmptySection from '../../atoms/EmptySection'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'
import { getBarChartBaseConfig } from './config'

const BarChart = ({
  data,
  color,
  theme,
  isLoading,
  chartProps,
  dataSetName,
  tooltipFormat,
  axisLeftFormat,
  emptySectionLabel
}) => {
  const { formatter } = useFormattingContext()

  if (isLoading) {
    return <Skeleton height='100%' width='100%' />
  }

  if (!isLoading && isEmpty(data)) {
    return (
      <EmptySection
        title={emptySectionLabel}
        description=''
        titleStyles={{
          fontSize: '2rem !important',
          fontWeight: 'bold'
        }}
      />
    )
  }

  return (
    <ResponsiveBar
      data={data}
      theme={theme}
      {...getBarChartBaseConfig({
        color,
        formatter,
        chartProps,
        dataSetName,
        tooltipFormat,
        axisLeftFormat
      })}
    />
  )
}

BarChart.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  chartProps: PropTypes.object,
  tooltipFormat: PropTypes.string,
  axisLeftFormat: PropTypes.string,
  emptySectionLabel: PropTypes.string,
  dataSetName: PropTypes.string,
  theme: PropTypes.object
}

BarChart.defaultProps = {
  data: [],
  color: '#4472C4',
  isLoading: false,
  chartProps: {},
  dataSetName: '',
  tooltipFormat: 'human',
  axisLeftFormat: 'marketValue',
  emptySectionLabel: 'No data found',
  theme: {
    fontFamily: 'Gotham-Book',
    fontSize: '12px'
  }
}

export default BarChart
