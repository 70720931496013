import React, { Children, Fragment, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import Text from '../../atoms/Text'
import AvailableDatePicker from '../../molecules/AvailableDatePicker'
import Skeleton from '../../atoms/Skeleton'

import { useSetViewContext, useViewContext } from '../../../redux/slices/viewContext'
import { useAppContext } from '../../../redux/slices/appContext'

import { EXPORT_CLASSNAME, SKELETON_VARIANTS } from '../../../constants'

import theme from '../../../theme'
import PrintViewHeader from '../../molecules/PrintViewHeader'
import { ContextContainerProvider } from '../../../abundanceEngine/components/ContextContainer'
import ExportRoot from '../../molecules/ExportButton/ExportRoot'
import useWindowResize from '../../../hooks/useWindowResize'
import SydButton from '../../commonDesign/Button'
import AddAccountToggle from '../AddAccount/AddAccountToggle'
import { setDataHasChanged, useAddAccountContext } from '../../../redux/slices/addAccountContext'
import queryClient from '../../../api/defaultQueryClient'
import { QUERY_KEYS } from '../../../api/queryKeys'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import ClientTearSheetDialogProvider from '../TearSheetDialog/ClientTearSheetDialogProvider'
import NetWorth from './NetWorth'
import {
  normalizeData,
  VisualBalanceFormatsShape,
  VisualBalanceHoldingTableColumnsShape,
  VisualBalanceHoldingTableDefaultSortShape,
  VisualBalanceLabelShape,
  VisualBalanceSectionsShape
} from './utils'
import VisualBalanceSheetContainer from './VisualBalanceSheetContainer'
import { useGetClientQuery, useVisualBalanceQuery } from './hooks'
import ViewToggles from './ViewToggles'
import VisualBalanceEmptyState from './VisualBalanceEmptyState'

const useStyles = makeStyles(() => ({
  skeleton: {
    position: 'relative',
    bottom: '10.625rem'
  },
  balanceSheetContainer: {
    marginBottom: '2.5rem',
    marginTop: '1.25rem',
    position: 'relative'
  },
  endContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px'
  },
  centerText: {
    textAlign: 'center'
  },
  viewToggleWrapper: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  [theme.breakpoints.down('xs')]: {
    centerText: {
      textAlign: 'left'
    },
    networthContainer: {
      flexBasis: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: '1.25rem'
    }
  }
}))

const datePickerStyles = {
  '& input': {
    textAlign: 'left'
  },
  fontSize: '0.875rem'
}

export const VBS_CONTEXT_KEY = 'vbs'

const VisualBalanceSheet = ({
  display,
  splitAssetClassTagIds,
  enableDrilldown,
  toggleOptions,
  showExport,
  showAddAsset,
  addAccountButtonLabel,
  wealthMissionPrefix,
  labels,
  sections,
  formats,
  holdingTable,
  showOtherAssetCards,
  maxBlocksPerGroupInDrilldown,
  accountSortingPriority,
  children
}) => {
  const classes = useStyles()
  const { clientId, availableDates, userId } = useAppContext()
  const setViewContext = useSetViewContext()
  const viewContext = useViewContext()
  const addAccountContext = useAddAccountContext()
  const dispatch = useDispatch()
  const { mainDate } = availableDates
  const { formatter } = useFormattingContext()

  const { data: vbsInfo, isFetching: isVbsLoading, refetch: refetchVbsData } = useVisualBalanceQuery({
    startDate: viewContext.mainDate,
    endDate: viewContext.mainDate,
    asOfDate: viewContext.mainDate,
    dateType: 'all',
    clientIds: [clientId],
    splitAssetClassTagIds: splitAssetClassTagIds,
    calcType: 'balance'
  }, {
    enabled: !!viewContext.mainDate && !!clientId,
    mapper: (data) => normalizeData(data, sections, splitAssetClassTagIds, accountSortingPriority)
  })

  const refetchAccountStatusData = useCallback(async () => {
    return await queryClient.refetchQueries([QUERY_KEYS.getAccountStatuses, userId])
  }, [userId])

  useEffect(() => {
    if (!addAccountContext.isOpen && addAccountContext.dataHasChanged) {
      dispatch(setDataHasChanged({ dataHasChanged: false }))
      refetchVbsData()
      refetchAccountStatusData()
    }
  }, [addAccountContext.isOpen, addAccountContext.dataHasChanged, dispatch, refetchVbsData, refetchAccountStatusData])

  const { data: client, isLoading: isClientLoading } = useGetClientQuery(clientId)

  const isLoading = useMemo(() => {
    return isClientLoading || isVbsLoading
  }, [isClientLoading, isVbsLoading])

  const onChangeView = (value) => {
    setViewContext({
      display: value
    })
  }

  const onChangeDate = (date) => {
    setViewContext({
      mainDate: dayjs(date).format('YYYY-MM-DD')
    })
  }

  const onClickVbs = (item) => {
    setViewContext({ selectedBlock: item })
  }

  const onPressAccount = (item) => {
    setViewContext({ selectedAccount: item })
  }

  const contextState = useMemo(() => ({
    name: VBS_CONTEXT_KEY,
    initialState: { splitAssetClassTagIds }
  }), [splitAssetClassTagIds])

  useEffect(() => {
    setViewContext({
      selectedAccount: {},
      display: display,
      selectedBlock: null,
      mainDate
    })
  }, [mainDate, setViewContext, display])

  const { height: windowHeight } = useWindowResize()
  const height = useMemo(() => {
    const heightOffset = showOtherAssetCards && !!vbsInfo?.other?.length ? 500 : 350
    return Math.max(((windowHeight ?? window.innerHeight) - heightOffset), 700)
  }, [showOtherAssetCards, vbsInfo, windowHeight])

  const vbsHasInfo = useMemo(() => (
    Object.values(vbsInfo ?? {}).flatMap(
      row => row.flatMap(group => group?.subgroups ?? [])
    )?.length > 0 ?? false
  ), [vbsInfo])

  const actionSlotChildren = useMemo(() => Children.toArray(children).filter(child => child.props?.slot === 'actionButtons'), [children])

  return (
    <ClientTearSheetDialogProvider>
      <ExportRoot enabled={showExport}>
        <ContextContainerProvider context={contextState}>
          <Container className={EXPORT_CLASSNAME} maxWidth='xl'>
            <Box sx={{ flexGrow: 1 }} pt={4}>
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
              >
                <PrintViewHeader />
                {isLoading ? (
                  <>
                    <Skeleton
                      width='100%'
                      height='92px'
                      variant={SKELETON_VARIANTS.text}
                    />
                    <Skeleton
                      width='100%'
                      height='800px'
                      variant={SKELETON_VARIANTS.text}
                      className={classes.skeleton}
                    />
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='center'
                    >
                      <Grid item className={classes.networthContainer}>
                        <NetWorth vbsInfo={vbsInfo} sections={sections} formats={formats.netWorth} formatter={formatter} />
                        <AvailableDatePicker
                          disableFuture
                          value={viewContext.mainDate}
                          onChange={onChangeDate}
                          type='date'
                          format='[As of] MMMM DD, YYYY'
                          extraStyles={datePickerStyles}
                        />
                      </Grid>
                      <Grid item style={{ flexGrow: 1 }}>
                        <Text
                          text={`${wealthMissionPrefix} ${
                          client?.description || 'Not available'
                          }`}
                          color={theme.palette.cloudBurst}
                          customFontSize='18px'
                          className={classes.centerText}
                        />
                      </Grid>
                      {(showAddAsset || !!actionSlotChildren?.length) && (
                        <Grid className={classes.endContent}>
                          <AddAccountToggle>
                            <SydButton
                              variant='primary'
                              size='sm'
                            >
                              {addAccountButtonLabel}
                            </SydButton>
                          </AddAccountToggle>

                          {actionSlotChildren}
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      className={classes.balanceSheetContainer}
                    >
                      {vbsHasInfo ? (
                        <>
                          <VisualBalanceSheetContainer
                            data={vbsInfo}
                            enableDrilldown={enableDrilldown}
                            labels={labels}
                            currentView={viewContext.display}
                            margin={1.25}
                            height={height}
                            client={client}
                            sections={sections}
                            formats={formats}
                            onClick={onClickVbs}
                            onPressAccount={onPressAccount}
                            splitAssetClassTagIds={splitAssetClassTagIds}
                            holdingTable={holdingTable}
                            showOtherAssetCards={showOtherAssetCards}
                            maxBlocksPerGroupInDrilldown={maxBlocksPerGroupInDrilldown}
                          />

                          <div className={classes.viewToggleWrapper}>
                            <ViewToggles
                              options={toggleOptions}
                              selectedValue={viewContext.display}
                              onChange={onChangeView}
                            />
                          </div>
                        </>
                      ) : (
                        <VisualBalanceEmptyState
                          title={labels.emptyState?.title}
                          subtitle={labels.emptyState?.subtitle}
                        />
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Container>
        </ContextContainerProvider>
      </ExportRoot>
    </ClientTearSheetDialogProvider>
  )
}

VisualBalanceSheet.propTypes = {
  display: PropTypes.string,
  labels: VisualBalanceLabelShape,
  splitAssetClassTagIds: PropTypes.arrayOf(PropTypes.number),
  enableDrilldown: PropTypes.bool,
  showAddAsset: PropTypes.bool,
  addAccountButtonLabel: PropTypes.string,
  showOtherAssetCards: PropTypes.bool,
  toggleOptions: PropTypes.arrayOf(PropTypes.string),
  showExport: PropTypes.bool,
  wealthMissionPrefix: PropTypes.string,
  sections: VisualBalanceSectionsShape,
  formats: VisualBalanceFormatsShape,
  holdingTable: PropTypes.shape({
    columns: PropTypes.arrayOf(VisualBalanceHoldingTableColumnsShape),
    defaultSort: PropTypes.arrayOf(VisualBalanceHoldingTableDefaultSortShape)
  }),
  maxBlocksPerGroupInDrilldown: PropTypes.number,
  accountSortingPriority: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    dir: PropTypes.string
  })),
  children: PropTypes.array
}

VisualBalanceSheet.defaultProps = {
  display: 'visual-balance-view',
  labels: {},
  splitAssetClassTagIds: [],
  enableDrilldown: false,
  showAddAsset: false,
  addAccountButtonLabel: 'Manage',
  showOtherAssetCards: false,
  toggleOptions: ['Visual Balance View', 'Split View'],
  showExport: false,
  wealthMissionPrefix: 'Wealth mission:',
  sections: {
    other: [],
    bottom: []
  },
  formats: {
    list: {
      overview: '0,00a',
      details: '0,00.0a'
    },
    tree: {
      overview: '0,00a',
      details: '0,00.0a'
    },
    netWorth: {
      primary: '$0,0a',
      alternate: '$0,000.00'
    }
  },
  holdingTable: {
    columns: [
      {
        accessor: 'assetName',
        Header: 'Holdings',
        alignRight: false
      },
      {
        accessor: 'allocationPercentage',
        Header: 'Allocation',
        format: 'percentage',
        alignRight: true,
        disableSortBy: true
      },
      {
        accessor: 'endingValue',
        Header: 'Total Value',
        format: '$0,000.0a',
        alignRight: true
      }
    ],
    defaultSort: [{ id: 'endingValue', desc: true }],
    maxBlocksPerGroupInDrilldown: 5
  },
  accountSortingPriority: [{ key: 'endingValue', dir: 'DESC' }]
}

export default VisualBalanceSheet
