/* eslint-disable react/jsx-boolean-value */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveLine } from '@nivo/line'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import SydButton from '../../../commonDesign/Button'
// import { values } from 'lodash'

const commonProperties = {
  animate: true
}

const ResearchLine = (props) => {
  const [hovered, setHovered] = React.useState(null)
  const minValue = () => {
    const minReturn = props.data.reduce((min, series) => {
      return series.data.reduce((innerMin, values) => {
        return Math.min(innerMin, values.y)
      }, min)
    }, 0)

    return minReturn < 0 ? minReturn * 1.2 : minReturn * 0.8
  }

  const handleOnHover = (id, type) => {
    if (type === 'enter') {
      setHovered(id)
    }
    if (type === 'leave') {
      setHovered(null)
    }
  }

  const normalizedData = useMemo(() => props.data.map(d => ({
    ...d,
    color: !hovered ? d.color : d.id === hovered ? d.color : '#eeeeee'
  })).sort((a, b) => {
    if (a.id === hovered) return -1
    if (b.id === hovered) return 1
    return 0
  }), [hovered, props.data])

  return (
    <Box pb={4}>
      <Box height='400px'>
        <ResponsiveLine
          {...commonProperties}
          data={normalizedData}
          curve='monotoneX'
          margin={{ top: 30, right: 30, bottom: 40, left: 90 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'day'
          }}
          xFormat='time: %m-%d-%Y'
          yScale={{
            type: 'linear',
            stacked: false,
            min: minValue()
          }}
          yFormat='%'
          axisLeft={{
            format: '.0%',
            legend: 'return',
            legendOffset: 24,
            tickValues: 5
          }}
          axisBottom={{
            format: '%m-%Y'
          }}
          onClick={(data) => {
            props.clickSeries(data)
          }}
          colors={(d) => d.color}
          isInteractive={true}
          layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh']}
          lineWidth={2}
          activeLineWidth={6}
          inactiveLineWidth={1}
          inactiveOpacity={0.15}
          enableGridX={false}
          pointSize={0}
          gridYValues={3}
          areaOpacity={0.85}
          useMesh={true}
          motionStiffness={175}
          motionDamping={25}
          theme={{
            grid: {
              line: {
                stroke: '#E2E2E2',
                strokeWidth: 1
              }
            },
            axis: {
              legend: {
                text: {
                  fontFamily: 'GothamPro',
                  fontWeight: 700
                }
              },
              ticks: {
                text: {
                  fontFamily: 'GothamPro',
                  fontWeight: 700,
                  fontSize: 13
                }
              }
            }
          }}
        />
      </Box>
      <Box display='flex' flexWrap='wrap' gridGap='6px' justifyContent='center' onMouseLeave={() => handleOnHover(null, 'leave')}>
        {props.data.map(d => (
          <div key={d.id} onMouseLeave={() => handleOnHover(d.id, 'leave')} onMouseEnter={() => handleOnHover(d.id, 'enter')}>
            <SydButton size='xs' onClick={() => props.clickSeries(d)}>
              <Box display='flex' alignItems='center' gridGap='4px'>
                <Box display='block' width={10} height={10} borderRadius={5} bgcolor={d.color} />
                <Text variant='body2'>{d.label}</Text>
              </Box>
            </SydButton>
          </div>
        ))}
      </Box>
    </Box>
  )
}

ResearchLine.propTypes = {
  data: PropTypes.array,
  clickSeries: PropTypes.func
}

export default ResearchLine
