import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import noop from 'lodash/noop'
import { useVaultContext } from '../VaultContextProvider'
import { useGetDefaultVisibility, useNormalizeLevelValues } from '../hooks'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import SydButton from '../../../commonDesign/Button'
import { defaultTogglesLabel, defaultViewSettings } from '../constants'
import { useStyles } from './styles'
import { getColumnConfig } from './columnConfig'
import VaultCollectionContent from './VaultCollectionContent'
import VaultCardGrid from './VaultCardGrid'
import VaultList from './VaultList'

const defaultLabels = {
  emptyState: {
    icon: 'paperMilestones',
    title: 'No documents found',
    subtitle: 'No documents exist yet.  Drag and drop documents here to add them'
  },
  addButton: {
    icon: 'add',
    label: 'Add Document',
    subtitle: ''
  }
}

const VaultCollection = ({
  title,
  levels: _levels,
  view: defaultView = 'grid',
  allowDetails = false,
  labels: _labels,
  skip = 0,
  take = 100,
  orderBy,
  filters: _filters = {},
  columns: _columns = ['name', 'tags', 'download', 'edit'],
  showTags = true,
  canUpload = false,
  showUploadButton = true,
  defaultVisibility: _defaultVisibility,
  collapsible = false,
  onEmpty = noop,
  hideIfEmpty = false,
  settings: _settings
}) => {
  const classes = useStyles()
  const [isCollapsed, setIsCollapsed] = useState(collapsible)
  const [documentCount, setDocumentCount] = useState(0)
  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)

  const history = useHistory()
  const { search } = useLocation()
  const queryClient = useQueryClient()
  const {
    filters,
    view: contextView,
    levels: contextLevels,
    labels: contextLabels,
    defaultVisibility: contextDefaultVisibility,
    showUploadOverlay,
    viewSettings
  } = useVaultContext()

  const settings = useMemo(() => ({
    ...defaultViewSettings,
    ...viewSettings,
    ..._settings
  }), [viewSettings, _settings])

  const view = useMemo(() => contextView ?? defaultView, [contextView, defaultView])

  const levels = useNormalizeLevelValues(_levels ?? contextLevels)

  const contextFilterTagIds = useMemo(() => {
    return filters.tags?.map(tag => tag.value)
  }, [filters.tags])

  const queryParams = useMemo(() => {
    const textSearch = filters.textSearch?.length
      ? { name: [{ op: 'contains', value: filters.textSearch }] } : {}

    const tagIdsFilter = [
      ..._filters.tagIds ?? [],
      ...contextFilterTagIds?.length ? [{ op: 'in', value: contextFilterTagIds }] : []
    ]

    return {
      filters: {
        ..._filters,
        levels,
        tagIds: tagIdsFilter?.length ? tagIdsFilter : undefined,
        status: [{ op: 'eq', value: 'uploaded' }]
      },
      textSearch,
      includes: {
        tags: true,
        createdByUser: true
      },
      includeThumbnailUrl: true,
      take,
      skip,
      orderBy
    }
  }, [filters.textSearch, _filters, levels, contextFilterTagIds, take, skip, orderBy])

  const columns = useMemo(() => getColumnConfig(_columns.filter(x => canUpload || x !== 'edit')), [_columns, canUpload])

  const handleDocumentClick = useCallback((document) => {
    if (!allowDetails) return
    const searchParams = new URLSearchParams(search)
    searchParams.set('documentId', document.documentId)
    history.push({ search: searchParams.toString() })
  }, [allowDetails, history, search])

  const refetch = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['list-documents'] })
    await queryClient.invalidateQueries({ queryKey: ['list-tags'] })
  }, [queryClient])

  const defaultVisibility = useGetDefaultVisibility(_defaultVisibility ?? contextDefaultVisibility ?? ['visible'])

  const handleOnLoaded = useCallback((documents) => {
    const documentCount = documents.length
    setDocumentCount(documentCount)
    if (documentCount === 0) {
      onEmpty()
    }
  }, [onEmpty])

  const handleResize = useCallback(() => {
    if (!containerRef.current) return false
    setContainerWidth(containerRef.current.offsetWidth)
  }, [])
  window.addEventListener('resize', handleResize)
  useEffect(() => {
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const needsCollapse = useMemo(() => {
    if (!containerRef.current) return false
    const approxDocsVisible = containerWidth / 240
    return documentCount >= approxDocsVisible
  }, [documentCount, containerWidth])
  const toggleLabels = useMemo(() => {
    return _labels?.toggles ?? contextLabels?.toggles ?? defaultTogglesLabel
  }, [_labels, contextLabels])

  return (
    <div className={classes.container} ref={containerRef}>
      <Box display='flex' gridGap={4} alignItems='center'>
        <span>{title?.length && (<div className={classes.sectionTitle}>{title}</div>)}</span>
        {collapsible && needsCollapse && (
          <SydButton
            size='sm'
            variant={toggleLabels.variant ?? 'primary'}
            className={clsx([classes.collapseButton], { [classes.collapseButtonActive]: !isCollapsed })}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <Icon name={ICON_NAMES.chevronDown} customSize={18} />
            {isCollapsed ? `${toggleLabels.expand} ${documentCount}` : toggleLabels.collapse}
          </SydButton>
        )}
      </Box>

      {levels.map(({ levelTypeId, levelIds }) => (
        <VaultCollectionContent
          key={levelTypeId}
          canUpload={canUpload}
          levelId={levelIds?.[0]}
          levelTypeId={levelTypeId}
          onRefetch={() => refetch()}
          showUploadOverlay={showUploadOverlay}
          showUploadButton={showUploadButton}
          labels={_labels ?? contextLabels ?? defaultLabels}
          defaultVisibility={defaultVisibility}
        >
          {['grid', 'grid_horizontal'].includes(view) && (
            <VaultCardGrid
              labels={_labels ?? contextLabels ?? defaultLabels}
              baseQueryParams={queryParams}
              showTags={showTags}
              view={view}
              onClick={handleDocumentClick}
              canUpload={canUpload}
              isCollapsed={isCollapsed}
              onLoaded={handleOnLoaded}
              hideIfEmpty={hideIfEmpty}
              settings={settings.grid}
            />
          )}

          {view === 'list' && (
            <VaultList
              labels={_labels ?? contextLabels ?? defaultLabels}
              columns={columns}
              baseQueryParams={queryParams}
              showTags={showTags}
              onClick={handleDocumentClick}
              canUpload={canUpload}
              maxLoadingCount={isCollapsed ? 5 : 10}
              hideIfEmpty={hideIfEmpty}
              settings={settings.list}
            />
          )}
        </VaultCollectionContent>
      ))}
    </div>
  )
}

VaultCollection.propTypes = {
  title: PropTypes.string,
  levels: PropTypes.arrayOf(PropTypes.shape({
    levelTypeId: PropTypes.number,
    levelIds: PropTypes.arrayOf(PropTypes.number)
  })),
  allowDetails: PropTypes.bool,
  showTags: PropTypes.bool,
  labels: PropTypes.object,
  view: PropTypes.oneOf(['grid', 'list', 'grid_horizontal']),
  filters: PropTypes.object,
  take: PropTypes.number,
  skip: PropTypes.number,
  orderBy: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    dir: PropTypes.oneOf(['asc', 'desc'])
  })),
  columns: PropTypes.arrayOf(PropTypes.string),
  canUpload: PropTypes.bool,
  showUploadButton: PropTypes.bool,
  defaultVisibility: PropTypes.arrayOf(PropTypes.string),
  collapsible: PropTypes.bool,
  onEmpty: PropTypes.func,
  hideIfEmpty: PropTypes.bool,
  settings: PropTypes.object
}

VaultCollection.defaultProps = {
  labels: defaultLabels
}

export default VaultCollection
