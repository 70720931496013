/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSortBy, useTable, useExpanded, useGlobalFilter, useColumnOrder } from 'react-table'
import { LinearProgress } from '@material-ui/core'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'
import { useTablePositioningContext } from '../StickySuperHeader/TablePositioningContext'
import { useDeepCompareMemo, useHorizontalOverflow } from '../../../hooks'
import { useTableStyles } from './tableStyles'
import PagingTotals from './PagingTotals'
import { operationalTablePropTypes } from './propTypes'
import ExpandyIcon from './ExpandyIcon'
import { useAutoOperationalTableContext } from './AutoOperationalTableContext'

const ClientOperationalTable = ({
  columns,
  data,
  defaultSort,
  top,
  total,
  searchText,
  onRowClick,
  itemName,
  loading,
  hideFooter,
  expandable,
  autoSticky,
  footNote,
  showTotalItemsLabel,
  variant
}) => {
  const hasOnRowClick = typeof onRowClick === 'function' && onRowClick.name !== 'noop'
  const { superHeaderRect } = useTablePositioningContext()
  const { setTableProps } = useAutoOperationalTableContext()

  const {
    hasHorizontalOverflow,
    containerRefCallback
  } = useHorizontalOverflow(autoSticky && !isEmpty(data))

  const classes = useTableStyles({
    top,
    loading,
    hasOnRowClick,
    superHeaderRect,
    hasHorizontalOverflow,
    variant
  })

  const defaultHiddenColumns = useMemo(
    () => columns.filter(({ hidden }) => hidden).map((col) => col.id),
    [columns]
  )

  const {
    getTableProps,
    headerGroups,
    rows,
    getTableBodyProps,
    prepareRow,
    setGlobalFilter,
    flatHeaders,
    flatRows,
    allColumns,
    setHiddenColumns,
    setColumnOrder
  } = useTable({
    columns,
    data,
    disableSortRemove: true,
    autoResetExpanded: false,
    initialState: {
      sortBy: defaultSort,
      hiddenColumns: defaultHiddenColumns
    }
  }, useGlobalFilter, useSortBy, useExpanded, useColumnOrder)

  const tableProps = useDeepCompareMemo(() => ({
    flatRows,
    flatHeaders,
    allColumns,
    setColumnOrder,
    setHiddenColumns
  }), [
    flatRows,
    allColumns,
    flatHeaders,
    setColumnOrder,
    setHiddenColumns
  ])

  useEffect(() => {
    if (!loading) {
      setTableProps?.(tableProps)
    }
  }, [loading, setTableProps, tableProps])

  useEffect(() => {
    setGlobalFilter(searchText)
  }, [searchText, setGlobalFilter])

  const rowClickHandler = useCallback(row => () => {
    if (expandable) {
      row.toggleRowExpanded()
    }
    if (onRowClick) {
      onRowClick(row)
    }
  }, [expandable, onRowClick])

  return (
    <div ref={containerRefCallback} className={classes.wrapper}>
      <div className={classes.spark}>
        <LinearProgress
          classes={{
            root: classes.sparkRoot
          }}
          variant='indeterminate'
        />
      </div>
      <table {...getTableProps()} className={clsx(classes[variant], 'operational-table')}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={clsx({
                    __right: column.alignRight
                  })}
                >
                  <div className='__thContainer'>
                    <span>{column.render('Header')}</span>
                    <span className='__sort-icon'>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <Icon name={ICON_NAMES.down} />
                          : <Icon name={ICON_NAMES.up} />
                        : ''}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={rowClickHandler(row)}
                className={clsx({
                  __child: (row.depth > 0),
                  __expanded: (row.isExpanded),
                  __expandable: expandable
                }, `__depth_${row.depth}`)}
              >
                {row.cells.map((cell, index) => (
                  <td
                    {...cell.getCellProps()}
                    className={clsx({
                      __right: cell.column.alignRight ?? false
                    })}
                  >
                    {index === 0 && expandable ? (
                      <div className='__expand-container'>
                        <ExpandyIcon className='__expand-handle' enabled={row.original?._next !== null} expanded={row.isExpanded} loading={row.original?._subRowsFetching} />
                        {cell.render('Cell')}
                      </div>
                    ) : cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {!hideFooter && (
        <div className={classes.footer}>
          <PagingTotals
            itemName={itemName}
            totalItems={total}
            hidePageSize
            footNote={footNote}
            showTotalItemsLabel={showTotalItemsLabel}
          />
        </div>
      )}
    </div>
  )
}

ClientOperationalTable.propTypes = operationalTablePropTypes

ClientOperationalTable.defaultProps = {
  itemName: 'Items',
  loading: false,
  hideFooter: false,
  expandable: false,
  autoSticky: false,
  footNote: null,
  variant: 'v1'
}

export default ClientOperationalTable
