import React from 'react'
import { ResponsiveWrapper } from '@nivo/core'
import RangedGauge from './RangedGauge'

function ResponsiveRangedGauge (props) {
  return (
    <ResponsiveWrapper>
      {({ width, height }) => (
        <RangedGauge width={width} height={height} {...props} />
      )}
    </ResponsiveWrapper>
  )
}

ResponsiveRangedGauge.propTypes = {
  ...RangedGauge.propTypes
}

ResponsiveRangedGauge.defaultProps = {
  ...RangedGauge.defaultProps
}

export default ResponsiveRangedGauge
