import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useAssetTearSheetViewById } from '../../../api/abundanceEngine'
import TearSheetProvider from '../../pages/tearSheet/TearSheetProvider'
import TearSheetView from '../../pages/tearSheet/TearSheetView'
import Loading from '../../molecules/Loading'

function TearSheetDialogForm ({ config }) {
  const { data: view, isLoading } = useAssetTearSheetViewById(config.tearSheetId)
  const tearSheetValue = useMemo(() => {
    return {
      levelTypeId: config.levelTypeId,
      levelId: config.levelId
    }
  }, [config])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TearSheetProvider value={tearSheetValue}>
          <TearSheetView
            jsx={view?.jsxView}
            bindings={{
              view,
              ...config.bindings
            }}
          />
        </TearSheetProvider>
      </Grid>
    </Grid>
  )
}

TearSheetDialogForm.propTypes = {
  config: PropTypes.shape({
    levelTypeId: PropTypes.number,
    levelId: PropTypes.number,
    tearSheetId: PropTypes.number,
    bindings: PropTypes.object
  })
}

export default TearSheetDialogForm
