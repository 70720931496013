import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import FastAvatar from '../../molecules/FastAvatar'
import Icon from '../../atoms/Icon'
import { useInternalUserId } from '../../../redux/slices/appContext'
import { usePolicy } from '../../../hooks/usePolicy'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useComments } from './CommentContext'
import CommentEditor from './CommentEditor'

const useStyles = makeStyles((theme) => ({
  comment: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '10px',
    '& header': {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.summitBlue
    },
    '& main': {
    },
    '& .__right': {
      flex: '1 0',
      maxWidth: '100%'
    }
  },
  headerGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  name: {
    fontWeight: '600'
  },
  timestamp: {
    fontWeight: '100',
    fontSize: '11px'
  },
  markdownContainer: {
    fontWeight: '100'
  },
  button: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function Comment ({ comment, authors }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const userId = useInternalUserId()
  const { deleteComment, editComment, editing, stopEditing, finishEditing } = useComments()

  const policyAllowed = usePolicy('comments_add')
  const allowEditAllComments = usePolicy('comments_edit_any')
  const canEdit = useMemo(() => {
    if (allowEditAllComments) { return true }
    return userId === comment.author && policyAllowed
  }, [userId, comment, policyAllowed, allowEditAllComments])

  const displayDate = useMemo(() => ({
    since: formatter(comment.publishDate, 'ß'),
    timestamp: formatter(comment.publishDate, '@M/D/YYYY HH:mm a')
  }), [comment.publishDate, formatter])

  const authorInfo = useMemo(() => {
    const result = authors.find(x => x.userId === comment.author) ?? {
      authorName: '',
      avatarUrl: null,
      avatarType: 'media'
    }
    return {
      ...result,
      abbreviation: result.authorName?.length ? result.authorName.at(0) : ''
    }
  }, [comment.author, authors])

  if (editing && editing?.commentKey === comment.commentKey) {
    return (
      <article>
        <CommentEditor
          defaultValue={comment.body}
          onSubmit={(body) => finishEditing(comment, body)}
          onCancel={stopEditing}
        />
      </article>
    )
  }

  return (
    <article className={classes.comment}>
      <div className='__right'>
        <header>
          <div className={classes.headerGroup}>
            <FastAvatar
              avatarUrl={authorInfo.avatarUrl}
              avatarType={authorInfo.media}
              abbreviation={authorInfo.abbreviation}
              size='xs'
            />
            <span className={classes.name}>{authorInfo.authorName}</span>
            <Tooltip title={displayDate.timestamp} placement='top'>
              <span className={classes.timestamp}>{displayDate.since}</span>
            </Tooltip>
          </div>
          {canEdit ? (
            <div className={classes.headerGroup}>
              <span className={classes.button} role='button' onClick={() => editComment(comment)}>
                <Icon name='edit' size={20} />
              </span>
              <span className={classes.button} role='button' onClick={() => deleteComment(comment)}>
                <Icon name='delete' size={20} />
              </span>
            </div>
          ) : null}
        </header>
        <main>
          <ReactMarkdown className={classes.markdownContainer} remarkPlugins={[remarkGfm]}>
            {comment.body}
          </ReactMarkdown>
        </main>
      </div>
    </article>
  )
}

Comment.propTypes = {
  comment: PropTypes.shape({
    body: PropTypes.string,
    author: PropTypes.any,
    publishDate: PropTypes.string,
    commentKey: PropTypes.string
  }),
  authors: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.number,
    authorName: PropTypes.string,
    avatarUrl: PropTypes.string
  }))
}

export default Comment
