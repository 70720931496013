import React, { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import { useColumns } from '../columnConfig'
import PresentationTable from '../../PresentationTable'
import { useResearchViewContext } from '../ResearchViewProvider'
import Text from '../../../atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import SydButton from '../../../commonDesign/Button'
import { useGetGroupedCoreData } from '../../../../api/coreData'
import useStyles from './styles'

const AssetDetailTable = () => {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const { setLevelFilters, levelFilters, dateRange, clientId } = useResearchViewContext()
  const { columns, defaultSort } = useColumns({
    dataType: 'assetDetail',
    formatter
  })

  const { data, isLoading, error } = useGetGroupedCoreData({
    dateRange,
    levelFilters: {
      ...levelFilters,
      levelTypes: ['asset'],
      dateType: 'all'
    }
  }, {
    mapper: (data) => (
      data?.map((item) => ({
        ...item,
        levelDisplay: `${item.assetLongName} (${item.Symbol && item.Symbol !== '' ? item.Symbol : item.CUSIP})`
      }))
    )
  })

  const downloadFileName = useMemo(() => {
    if (levelFilters.assetIds) {
      return `asset_${levelFilters.assetIds.join('_')}_details`
    }
    return `client_${clientId}_all_assets_details`
  }, [clientId, levelFilters.assetIds])

  const levelRowClick = (row) => {
    setLevelFilters(previous => ({ ...previous, assetIds: [row.original.assetId] }))
  }

  if (error) return <div>Error {JSON.stringify(error)}</div>

  return (
    <>
      <div className={classes.header}>
        <Text variant={TEXT_VARIANTS.h3}>
          {levelFilters.assetIds ? (
            <>
              Asset
              {levelFilters.assetIds.map(assetId => (<div key={assetId} className={classes.label}>{assetId}</div>))}
            </>
          ) : 'All Assets'}
        </Text>
        <div>
          {!!data?.length && (
            <CSVLink data={data} filename={downloadFileName}>
              <SydButton variant='primary' icon={ICON_NAMES.download} size='sm'>
                Download CSV
              </SydButton>
            </CSVLink>
          )}
        </div>
      </div>
      <PresentationTable
        columns={columns}
        data={data ?? []}
        loading={isLoading}
        onRowClick={levelRowClick}
        defaultSort={defaultSort}
        sortable
      />
    </>
  )
}

export default AssetDetailTable
