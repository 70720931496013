import React, { useMemo } from 'react'
import { Pie } from '@nivo/pie'
import PropTypes from 'prop-types'
import { mapToSemicircleDegrees } from '../shared/tools'
import { useMarkerLayer } from './MarkerLayer'
import { useValueLayer } from './ValueLayer'
import { useSubtitleLayer } from './SubtitleLayer'
import RangeTooltip from './RangeTooltip'

function RangedGauge ({
  width,
  height,
  ranges,
  marker,
  subTitle,
  cornerRadius,
  padAngle,
  rangeTooltip,
  valueFormat,
  useRangeValue,
  valueSizeFactor,
  ...props
}) {
  const { data, minStart, maxEnd } = useMemo(() => {
    const projectedRanges = ranges.map(r => ({
      value: r.end - r.start,
      ...r
    }))
    const minStart = ranges.reduce((p, c) => c.start < p ? c.start : p, Number.POSITIVE_INFINITY)
    const maxEnd = ranges.reduce((p, c) => c.end > p ? c.end : p, Number.NEGATIVE_INFINITY)

    return {
      data: projectedRanges,
      minStart,
      maxEnd,
      rangeMagnitude: maxEnd - minStart
    }
  }, [ranges])

  const valueDegrees = useMemo(() => mapToSemicircleDegrees(minStart, maxEnd, marker?.value), [minStart, maxEnd, marker])

  /** This is for displaying matching range titles instead of a numeric value */
  const _marker = useMemo(() => {
    if (useRangeValue) {
      const matchingRange = ranges.find(r => marker.value >= r.start && marker.value <= r.end)
      return {
        ...marker,
        value: matchingRange.id,
        format: null
      }
    } else {
      return {
        ...marker,
        format: valueFormat
      }
    }
  }, [useRangeValue, marker, ranges, valueFormat])

  const markerLayer = useMarkerLayer(valueDegrees, marker, height)
  const valueLayer = useValueLayer(_marker, valueSizeFactor, height)
  const subTitleLayer = useSubtitleLayer(subTitle, height)

  const layers = useMemo(() => {
    return ['arcs', markerLayer, valueLayer, subTitleLayer]
  }, [markerLayer, valueLayer, subTitleLayer])

  return (
    <Pie
      layers={layers}
      width={width}
      height={height}
      data={data}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.9}
      cornerRadius={cornerRadius}
      padAngle={padAngle}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      tooltip={rangeTooltip}
      {...props}
    />
  )
}

RangedGauge.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,

  ranges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    color: PropTypes.string
  })),
  marker: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    format: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.number
  }),
  subTitle: PropTypes.string,
  useRangeValue: PropTypes.bool,
  valueSizeFactor: PropTypes.number,

  cornerRadius: PropTypes.number,
  padAngle: PropTypes.number,

  rangeTooltip: PropTypes.func,
  valueFormat: PropTypes.string
}

RangedGauge.defaultProps = {
  subTitle: 'Probability of Success',
  cornerRadius: 200,
  padAngle: 1,
  colors: c => c.data.color,
  valueFormat: 'percentage',
  valueSizeFactor: 0.2,
  rangeTooltip: RangeTooltip
}

export default RangedGauge
