import { ICON_NAMES } from '../../../constants'

export const defaultDocumentDetailsLabel = {
  title: 'Document Details',
  backButton: 'Document Vault',
  backButtonStyle: {
    variant: 'ghost'
  },
  downloadButton: 'Download',
  attachmentDownloadButton: 'Download to Computer',
  deleteButton: 'Delete',
  editButton: 'Edit Document',
  tags: 'Tags:',
  description: 'Description:',
  createdBy: 'Created by:',
  emptyData: {
    icon: ICON_NAMES.paperMilestones,
    title: 'Document not found',
    subtitle: 'The document you are looking for does not exist, has been deleted, or you do not have access to it'
  }
}

export const defaultVisibilityLabel = {
  internal: {
    icon: ICON_NAMES.suitcase,
    label: 'Internal',
    description: 'Only visible to other advisors'
  },
  hidden: {
    icon: ICON_NAMES.lock,
    label: 'Private',
    description: 'Only visible to you'
  },
  visible: {
    icon: ICON_NAMES.visibility,
    label: 'Public',
    description: 'Visible to users with access to this vault'
  }
}

export const defaultCreateDocumentLabel = {
  icon: 'add',
  label: 'Add Doc'
}

export const defaultTogglesLabel = {
  variant: 'primary',
  expand: 'View all',
  collapse: 'Collapse'
}

export const defaultCollectionsLabel = {
  1000000: {
    untagged: 'Untagged',
    recent: 'Recent'
  },
  201: {
    untagged: 'Untagged',
    recent: 'Recent'
  }
}

export const defaultViewSettings = {
  list: {
    defaultExpanded: true
  },
  grid: {
    downloadContentDisposition: 'inline'
  }
}
