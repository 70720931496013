import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { createDataTestId } from '../../../utils'
import NumberFormat from '../../atoms/NumberFormat'
import Text from '../../atoms/Text'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import FastAvatar from '../../molecules/FastAvatar'
import { getPercentageOfTotal, VisualBalanceDataShape } from './utils'
import TreeMapBlockV2 from './TreeMapBlockV2'

const STANDARD_HEIGHT = 700
const BLOCKS_MARGIN = 15
const MIN_HEIGHT_PERCENTAGE = 10

const useStyles = makeStyles((theme) => ({
  container: ({ totalHeight }) => ({
    flexGrow: 1,
    padding: '1rem',
    minHeight: totalHeight
  }),
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: BLOCKS_MARGIN * 2
  },
  stripeBackground: {
    borderRadius: '0.5rem',
    background:
      'repeating-linear-gradient(45deg, #dedede, #dedede 5px, #FFF 5px, #FFF 10px)'
  },
  treeDividerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: `0 ${BLOCKS_MARGIN / 2}px`
  },
  treeDividerLine: {
    width: '1.25rem',
    borderBottom: '3px solid #DEDEDE',
    margin: 0
  },
  treeDividerBranches: {
    width: '1.5rem',
    height: '100%',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: 0,
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: 0,
    borderRight: 0,
    border: '3px solid #DEDEDE'
  },
  blockContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  blockTitleContainer: {
    display: 'flex',
    minWidth: '12rem',
    paddingLeft: '1.5rem',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  blockTitle: {
    lineHeight: '1.2',
    fontSize: '1.25rem'
  },
  blockSubTitle: {
    fontWeight: 400,
    lineHeight: '1.0',
    fontSize: '0.75rem',
    marginTop: '0.25rem',
    color: theme.palette.dustyGray
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: BLOCKS_MARGIN
  },
  horizontalBlock: {
    minHeight: '3.5rem'
  },
  topContainer: ({ topContainerHeight }) => ({
    minHeight: topContainerHeight,
    flexDirection: 'column',
    gap: BLOCKS_MARGIN
  }),
  bottomContainer: ({ bottomContainerHeight }) => ({
    minHeight: bottomContainerHeight
  }),
  [theme.breakpoints.down('sm')]: {
    header: {
      display: 'none'
    },
    topContainer: {
      height: 'auto !important'
    },
    bottomContainer: {
      height: 'auto !important'
    },
    treeDividerContainer: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      margin: 0,
      height: '3rem'
    },
    treeDividerLine: {
      width: 'auto',
      height: '1.25rem',
      borderLeft: '3px solid #DEDEDE',
      margin: 0
    },
    bottomDivider: {
      borderLeft: 'none',
      height: 0
    },
    treeDividerBranches: {
      width: '100%',
      borderRadius: 0,
      borderTopRightRadius: '1rem',
      borderTopLeftRadius: '1rem',
      borderRight: '3px solid #DEDEDE',
      borderBottom: 0
    },
    blockTitleContainer: {
      minWidth: 0,
      position: 'absolute',
      bottom: '.75rem',
      left: '.75rem',
      paddingLeft: '0 !important',
      pointerEvents: 'none'
    },
    blockTitle: {
      fontSize: '1rem !important'
    },
    blockSubTitle: {
      color: theme.palette.black
    },
    horizontalBlock: {
      minHeight: '4.25rem !important'
    }
  }
}))

const getSectionHeights = (dataSections, height) => {
  const totalAssetsValue = [dataSections.bottom, dataSections.core, dataSections.side]
    .flat().reduce((acc, row) => acc + Math.abs(row.total), 0)
  const totalTopRatio = [dataSections.core, dataSections.side]
    .flat().reduce((acc, row) => acc + Math.abs(row.total), 0) / totalAssetsValue
  const totalBottomRatio = dataSections.bottom.reduce((acc, row) => acc + Math.abs(row.total), 0) / totalAssetsValue

  return {
    top: (height * totalTopRatio + (dataSections.core?.length - 1) * BLOCKS_MARGIN) - (dataSections.bottom?.length ? 70 : 0),
    bottom: !dataSections.bottom?.length
      ? 0
      : Math.max((height * totalBottomRatio + (dataSections.bottom?.length - 1) * BLOCKS_MARGIN), 75)
  }
}

function VisualBalanceTreeMapChart ({
  client,
  data,
  height,
  totalTitle,
  blocksTitle,
  onClick,
  width,
  overviewFormat,
  detailFormat
}) {
  const theme = useTheme()
  const fixedHeight = height || STANDARD_HEIGHT
  const sectionHeights = useMemo(() => getSectionHeights(data, fixedHeight), [data, fixedHeight])
  const { core: _core, bottom, side } = data
  const core = _core.filter((row) => row.total > 0)
  const { formatter } = useFormattingContext()

  const classes = useStyles({
    width,
    topContainerHeight: sectionHeights.top,
    bottomContainerHeight: sectionHeights.bottom
  })

  const topSectionPercentages = useMemo(() => {
    const topSectionTotal = [core, side].flat().reduce((acc, row) => Math.abs(row.total) + acc, 0)

    return {
      core: getPercentageOfTotal(core.reduce((acc, row) => Math.abs(row.total) + acc, 0), topSectionTotal),
      side: getPercentageOfTotal(side.reduce((acc, row) => Math.abs(row.total) + acc, 0), topSectionTotal)
    }
  }, [core, side])

  const renderedDivider = useMemo(
    () => (
      <Box className={classes.treeDividerContainer}>
        <Box className={classes.treeDividerLine} />
        <Box className={classes.treeDividerBranches} />
      </Box>
    ),
    [
      classes.treeDividerLine,
      classes.treeDividerBranches,
      classes.treeDividerContainer
    ]
  )

  const renderGroupTitle = useCallback(
    ({ total, groupLongName, groupColorField }, isStriped) => {
      const title = groupLongName
      let number = formatter(total, overviewFormat)
      if (total < 0) {
        number = `(${number})`
      }

      const backgroundColor = groupColorField ?? '#C4E4D3'

      return (
        <div key={'groupTitle-' + title} className={classes.blockContainer}>
          <TreeMapBlockV2
            titleText={
              <Box color={theme.palette.getContrastText(backgroundColor)}>
                <NumberFormat
                  title={formatter(total, '0,0.00')}
                  number={number}
                  skipFormat
                  useParenthesis
                />
              </Box>
            }
            subtitleText={title}
            backgroundColor={backgroundColor}
            useStripedBackground={isStriped}
            detailsBackgroundColor={isStriped ? '#FFFFFF' : undefined}
            value={100}
            blockValue={Number(number)}
            tooltipNumberFormat={detailFormat}
          />
        </div>
      )
    },
    [classes.blockContainer, formatter, detailFormat, overviewFormat, theme.palette]
  )

  const getAmountFormat = useCallback((value, format = '0.0a') => {
    return formatter(value, format)
  }, [formatter])

  const renderBlockLabel = useCallback(
    ({ blockValue, subtitle, isLiabilityBlock = false }) => {
      let title = formatter(blockValue, '0,0.00')
      let number = getAmountFormat(blockValue, overviewFormat)

      if (isLiabilityBlock || blockValue < 0) {
        title = `(${formatter(Math.abs(blockValue), '0,0.00')})`
        number = `(${getAmountFormat(blockValue, overviewFormat)})`
      }
      return (
        <div className={classes.blockTitleContainer}>
          <div className={classes.blockTitle}>
            <NumberFormat title={title} number={number} skipFormat />
          </div>
          <div className={classes.blockSubTitle}>{subtitle}</div>
        </div>
      )
    },
    [formatter, getAmountFormat, overviewFormat, classes.blockTitleContainer, classes.blockTitle, classes.blockSubTitle]
  )

  const renderBlock = useCallback((block, isStriped = false) => {
    const { accountCategoryGroupId, total, colorField, displayName, accounts, percentage } = block
    const backgroundColor = colorField || '#C4E4D3'
    const height = `${percentage}%`

    return (
      <div
        className={clsx(classes.horizontalBlock)}
        key={'categoryGroup-' + accountCategoryGroupId}
        style={{ height }}
        onClick={() => onClick({ isStriped, ...block, height: percentage })}
      >
        <Box
          height='100%'
          display='flex'
          flexDirection='row'
          position='relative'
        >
          <TreeMapBlockV2
            titleText={
              <NumberFormat
                title={displayName}
                number={getAmountFormat(total)}
                skipFormat
              />
            }
            showDetails
            details={accounts.map(row => ({
              ...row,
              levelTypeId: 1,
              levelId: row.accountId,
              title: row.displayName ?? row.accountName ?? row.accountLongName
            }))}
            value={percentage}
            subtitleText={displayName}
            blockValue={total}
            backgroundColor={backgroundColor}
            useStripedBackground={isStriped}
            detailsBackgroundColor={isStriped ? '#FFFFFF' : undefined}
            dataTestId={createDataTestId(displayName, '-')}
            tooltipNumberFormat={detailFormat}
          />
          {renderBlockLabel({
            blockValue: total,
            subtitle: displayName
          })}
        </Box>
      </div>
    )
  }, [classes.horizontalBlock, getAmountFormat, detailFormat, renderBlockLabel, onClick])

  const renderDataSection = useCallback((dataSection, sectionLocation) => {
    const percentage = dataSection.percentage ?? MIN_HEIGHT_PERCENTAGE
    const flex = `${percentage} 0`

    return (
      <Grid key={'treeSection-' + dataSection.groupId} container style={{ flex }}>
        <Grid item md={2} xs={12}>
          {renderGroupTitle(dataSection, sectionLocation === 'bottom')}
        </Grid>
        <Grid item md={10} xs={12}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: { sm: 'column', xs: 'column', md: 'row' },
              position: 'relative'
            }}
          >
            {renderedDivider}
            <Box
              display='flex'
              flexDirection='column'
              flexGrow={1}
              style={{ gap: BLOCKS_MARGIN }}
            >
              {dataSection?.subgroups.map(subgroup => renderBlock(subgroup, sectionLocation === 'bottom'))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }, [renderGroupTitle, renderBlock, renderedDivider])

  return (
    <>
      <Grid container className={classes.header}>
        <Grid item xs={1} />
        <Grid item xs={11} style={{ paddingLeft: '4.5rem' }}>
          <Grid container>
            <Grid item xs={2}>
              <Box>
                <Text
                  customFontSize='1.5rem'
                  customFontWeight='bold'
                  text={totalTitle}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box ml='3.75rem'>
                <Text
                  customFontSize='1.5rem'
                  customFontWeight='bold'
                  text={blocksTitle}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.container}>
        <Grid item xs={12} md={1}>
          <Box
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <FastAvatar
              size='xl'
              avatarUrl={client?.avatarUrl}
              abbreviation={client?.clientAbbreviation}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={11}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              position: 'relative'
            }}
          >
            {renderedDivider}
            <Grid container>
              <Grid item xs={12} className={classes.mainContainer}>
                <Grid
                  container
                  className={classes.topContainer}
                >
                  {!!side.length &&
                    <div className={classes.sectionContainer} style={{ flex: `${topSectionPercentages.side} 0` }}>
                      {side.map(dataSection => renderDataSection(dataSection, 'side'))}
                    </div>}
                  {!!core.length &&
                    <div className={classes.sectionContainer} style={{ flex: `${topSectionPercentages.core} 0` }}>
                      {core.map(dataSection => renderDataSection(dataSection, 'core'))}
                    </div>}

                </Grid>

                {!!bottom?.length &&
                  <>
                    <Box
                      my={3}
                      height={0}
                      width='100% !important'
                      style={{
                        opacity: 0.2
                      }}
                      className={clsx(classes.treeDividerLine, classes.bottomDivider)}
                    />

                    <Grid
                      container
                      className={classes.bottomContainer}
                    >
                      {bottom.map(dataSection => renderDataSection(dataSection, 'bottom'))}
                    </Grid>
                  </>}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

VisualBalanceTreeMapChart.propTypes = {
  client: PropTypes.shape({
    clientAbbreviation: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  data: PropTypes.shape(VisualBalanceDataShape),
  height: PropTypes.number,
  totalTitle: PropTypes.string,
  blocksTitle: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  overviewFormat: PropTypes.string,
  detailFormat: PropTypes.string
}

VisualBalanceTreeMapChart.defaultProps = {
  data: [],
  height: undefined,
  totalTitle: 'Breakdown',
  blocksTitle: 'Assets & Liabilities',
  onClick: noop,
  width: undefined
}

export default VisualBalanceTreeMapChart
