import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core'
import Modal from '../../../../molecules/Modal'
import Text from '../../../../atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import theme from '../../../../../theme'
import { useSearchClientsMultiple } from '../../../../../api/clients'
import PostToVaultForm from './PostToVaultForm'
import { useGetTagsData } from './hooks.js'

const useStyles = makeStyles(() => ({
  modal: {
    borderRadius: '.5rem',
    padding: '.15rem'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 1',
    padding: '1rem 0',
    alignItems: 'center',
    maxWidth: '900px',
    width: '95%',
    margin: '0 auto'
  },
  iframeContainer: {
    flex: '1 0',
    width: '100%',
    position: 'relative'
  },
  iframe: {
    display: 'block',
    border: 'none',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}))

const PostToVaultModal = ({
  data,
  onClose,
  onSubmit
}) => {
  const classes = useStyles()

  const isOpen = useMemo(() => !!Object.keys(data)?.length, [data])

  // tags
  const { data: tags, loading: isTagsLoading } = useGetTagsData()

  const getWealthOwnersFromClient = ({ clients: { data } }) => {
    return data[0]?.wealthOwners.filter(user => user.acceptedTC?.length) ?? []
  }

  const { data: wealthOwners, isLoading: isUsersLoading } = useSearchClientsMultiple({
    clients: {
      includes: { wealthOwners: true },
      filters: { clientId: [{ op: 'in', value: [data.clientId] }] }
    }
  }, getWealthOwnersFromClient, !!data?.clientId)

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      containerClassName={classes.modal}
      width='30rem'
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <div>
            <Text variant={TEXT_VARIANTS.h2}>Share to Vault</Text>
            {data.batchRunId
              ? (
                <Text variant={TEXT_VARIANTS.subtitle}>
                  This will move the reports in this batch to the client's vault
                </Text>
              )
              : <Text variant={TEXT_VARIANTS.subtitle}>This will move the report to the client's vault</Text>}
          </div>
          <button
            type='button'
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.close}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </div>
        <div>
          {!!Object.keys(data)?.length &&
            <PostToVaultForm
              clientId={data.clientId}
              reportRunId={data.reportRunId}
              batchRunId={data.batchRunId}
              tags={tags}
              users={wealthOwners}
              isUsersLoading={isUsersLoading}
              isTagsLoading={isTagsLoading}
              onSubmit={onSubmit}
              onCancel={onClose}
            />}
        </div>
      </div>
    </Modal>
  )
}

PostToVaultModal.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

PostToVaultModal.defaultProps = {
  data: {},
  onSubmit: noop
}

export default PostToVaultModal
