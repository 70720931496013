import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useMemo } from 'react'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { CALC_TYPES } from '../../../../../constants'
import { useCoreChartData } from '../../../../../api/coreData'
import { useWealthJourney } from '../WealthJourneyProvider'

dayjs.extend(utc)

function useChartBounds ({
  data,
  chartBounds,
  seriesStartDate,
  seriesEndDate,
  dataEndDate
}) {
  const { entries } = useWealthJourney()

  const calculatedBounds = useMemo(() => {
    const by = chartBounds?.by ?? 'series'
    switch (by) {
      case 'series-data-end':
        return {
          start: seriesStartDate,
          end: dataEndDate
        }
      case 'entries': {
        const result = entries.reduce((acc, entry) => {
          const entryDate = dayjs.utc(entry.entryDate)
          return {
            start: entryDate.isBefore(dayjs.utc(acc.start)) ? entryDate : acc.start,
            end: entryDate.isAfter(dayjs.utc(acc.end)) ? entryDate : acc.end
          }
        }, {
          start: dayjs().startOf('year'),
          end: dayjs().endOf('year')
        })

        return {
          start: result.start.add(-2, 'months').format('YYYY-MM-DD'),
          end: result.end.add(2, 'months').format('YYYY-MM-DD')
        }
      }
      case 'custom':
        return {
          start: dayjs(chartBounds.start).format('YYYY-MM-DD'),
          end: dayjs(chartBounds.end).format('YYYY-MM-DD')
        }
      case 'series':
      default:
        return {
          start: seriesStartDate,
          end: seriesEndDate
        }
    }
  }, [chartBounds, seriesStartDate, seriesEndDate, dataEndDate, entries])

  // The chart needs the series data filled in, if we don't actually have series data
  return useMemo(() => {
    const d = data?.data || []

    const cd = d.reduce((prev, cur) => {
      const s = dayjs.utc(calculatedBounds.start)
      const e = dayjs.utc(calculatedBounds.end)
      const hasMin = cur.data.some(d => {
        const dx = dayjs.utc(d.x)
        return dx.isSame(s) || dx.isBefore(s)
      })
      const hasMax = cur.data.some(d => {
        const dx = dayjs.utc(d.x)
        return dx.isSame(e) || dx.isAfter(e)
      })

      if (!hasMin) {
        cur.data.splice(0, 0, { x: s.format('YYYY/MM/DD'), y: null })
      }
      if (!hasMax) {
        cur.data.push({ x: e.format('YYYY/MM/DD'), y: null })
      }

      cur.data = cur.data.filter(item => {
        const ix = dayjs.utc(item.x)
        return (ix.isSame(s) || ix.isAfter(s)) && (ix.isSame(e) || ix.isBefore(e))
      })
      prev.push(cur)
      return prev
    }, [])

    return {
      data: cd,
      ...calculatedBounds
    }
  }, [data, calculatedBounds])
}

export const useWjSeries2 = ({ baseQuery, chartBounds, disableLineLayer }) => {
  const { clientId, availableDates } = useAppContext()
  const scopeFilter = useMemo(() => {
    return {
      levelTypes: ['client'],
      clientIds: [clientId]
    }
  }, [clientId])

  const query = useMemo(() => {
    const startDate = dayjs.utc(availableDates.min)
    const endDate = dayjs.utc(availableDates.max)

    return {
      ...(baseQuery || {}),
      levelFilters: {
        ...(baseQuery?.levelFilters || {}),
        // who are we getting this data for? a client? a bear?
        ...(scopeFilter || {}),
        calcType: CALC_TYPES.timeSeries
      },
      dateRange: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
    }
  }, [scopeFilter, availableDates, baseQuery])

  // We may or may not be requesting chart data
  const { data, isLoading } = useCoreChartData(query)
  const bounds = useChartBounds({
    data,
    chartBounds,
    seriesStartDate: data?.startDate,
    seriesEndDate: data?.endDate,
    dataEndDate: data?.dataEndDate
  })

  return {
    data: bounds?.data || [],
    extents: data?.extents,
    isLoading: isLoading,
    seriesStartDate: bounds?.start,
    seriesEndDate: bounds?.end,
    dataEndDate: data?.dataEndDate
  }
}
