import { useMemo } from 'react'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchClients } from '../../../api/clients'
import { useGroupTypeSearch } from '../../../api/groups'

export const useRangedGaugeData = (groupTypeId, keyDatapoint, otherProps = {}) => {
  const { clientId } = useAppContext()
  const { markerColor, valueFormat, markerRadius } = otherProps
  const queries = useMemo(() => {
    return {
      client: {
        limit: 1,
        filters: {
          clientId: [{ op: 'eq', value: clientId }]
        },
        includes: {
          keyDatapoints: [keyDatapoint]
        }
      },
      groupType: {
        limit: 1,
        filters: {
          groupTypeId: [{ op: 'eq', value: groupTypeId }]
        },
        includes: {
          groupBreakpoints: true
        }
      }
    }
  }, [groupTypeId, keyDatapoint, clientId])
  const clientResponse = useSearchClients(queries.client)
  const groupTypeResponse = useGroupTypeSearch(queries.groupType)

  const marker = useMemo(() => {
    if (clientResponse.isLoading) return null
    const c = clientResponse.data?.data.length ? clientResponse.data.data.at(0) : null
    if (!c) return null
    const dp = keyDatapoint in c.keyDatapoints ? c.keyDatapoints[keyDatapoint] : null
    if (!dp) return null

    return {
      id: dp.displayName,
      value: dp.value,
      color: markerColor,
      format: valueFormat,
      radius: markerRadius
    }
  }, [clientResponse.data, clientResponse.isLoading, keyDatapoint, markerColor, markerRadius, valueFormat])

  const ranges = useMemo(() => {
    if (groupTypeResponse.isLoading) return null
    const groupType = groupTypeResponse.data?.data.length ? groupTypeResponse.data.data.at(0) : null
    if (groupType === null) return null

    return groupType.groupBreakpoints?.map(bp => ({
      id: bp.breakpointName,
      start: +bp.lowerLimit,
      end: +bp.upperLimit,
      color: bp.colorField
    })) ?? null
  }, [groupTypeResponse.data, groupTypeResponse.isLoading])

  return {
    marker,
    ranges,
    isLoading: clientResponse.isLoading || groupTypeResponse.isLoading,
    error: clientResponse.error || groupTypeResponse.error
  }
}

export const useSimpleGaugeData = (keyDatapoint, otherProps = {}) => {
  const { clientId } = useAppContext()
  const { markerColor, valueFormat } = otherProps
  const queries = useMemo(() => {
    return {
      client: {
        limit: 1,
        filters: {
          clientId: [{ op: 'eq', value: clientId }]
        },
        includes: {
          keyDatapoints: [keyDatapoint]
        }
      }
    }
  }, [keyDatapoint, clientId])
  const clientResponse = useSearchClients(queries.client)

  const marker = useMemo(() => {
    if (clientResponse.isLoading) return null
    const c = clientResponse.data?.data.length ? clientResponse.data.data.at(0) : null
    if (!c) return null
    const dp = keyDatapoint in c.keyDatapoints ? c.keyDatapoints[keyDatapoint] : null
    if (!dp) return null

    return {
      id: dp.displayName,
      value: dp.value,
      color: markerColor,
      format: valueFormat
    }
  }, [clientResponse.data, clientResponse.isLoading, keyDatapoint, markerColor, valueFormat])

  return {
    marker,
    isLoading: clientResponse.isLoading,
    error: clientResponse.error
  }
}
