import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useClient } from '../../../api/clients'
import { useResearchViewContext } from './ResearchViewProvider'

const useStyles = makeStyles((theme) => ({
  badge: {
    display: 'inline-block'
  },
  badgeContent: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 8px',
    margin: '-6px 0',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    fontSize: '1rem'
  }
}))

const ClientNameDisplay = () => {
  const classes = useStyles()
  const { clientId } = useResearchViewContext()
  const { data: client, isLoading } = useClient(clientId, { enabled: !!clientId })

  return (
    <div className={classes.badge}>
      <div className={classes.badgeContent}>
        {isLoading ? (
          <>
            Loading...
          </>
        ) : client?.longName}
      </div>
    </div>
  )
}

export default ClientNameDisplay
