import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../redux/slices/appContext'
import EmptySection from '../../atoms/EmptySection'
import { useLevelThings } from '../../../api/things'
import Loading from '../../molecules/Loading'
import { ThingContext } from './ThingContext'

function ThingProvider ({ children, noData, levelTypeId, levelId, showLoading }) {
  const { clientId } = useAppContext()
  const _levelId = useMemo(() => {
    if (levelTypeId === 201) {
      return clientId
    }
    return levelId
  }, [levelTypeId, levelId, clientId])

  const things = useLevelThings(levelTypeId, _levelId)

  return (
    <ThingContext.Provider value={things}>
      {things.isLoading
        ? showLoading
          ? (
            <Loading />
          ) : null
        : (
          things?.data?.things?.length ? children : noData
        )}
    </ThingContext.Provider>
  )
}

ThingProvider.propTypes = {
  children: PropTypes.node,
  noData: PropTypes.node,
  levelTypeId: PropTypes.number,
  levelId: PropTypes.number,
  showLoading: PropTypes.bool
}

ThingProvider.defaultProps = {
  levelTypeId: 201,
  noData: (
    <EmptySection
      title='No Data Available'
      description=''
    />
  )
}

export default ThingProvider
