import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import Icon from '../../atoms/Icon'
import { useClientTearSheetManager } from './ClientTearSheetDialogProvider'

function TearSheetOpenIcon ({ className, style, levelTypeId, levelId, buttonProps = {}, iconProps = {} }) {
  const tearSheetManager = useClientTearSheetManager()
  if (tearSheetManager.getTearSheet(levelTypeId, levelId)) {
    return (
      <div role='button' className={className} style={style}>
        <IconButton
          {...buttonProps}
          onClick={(e) => {
            e.stopPropagation()
            return tearSheetManager.open(levelTypeId, levelId)
          }}
        >
          <Icon name='expand' {...iconProps} />
        </IconButton>
      </div>
    )
  }

  return null
}

TearSheetOpenIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  levelTypeId: PropTypes.number.isRequired,
  levelId: PropTypes.number.isRequired,
  buttonProps: PropTypes.object,
  iconProps: PropTypes.object
}

export default TearSheetOpenIcon
