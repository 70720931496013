import React, { useMemo } from 'react'
import { Pie } from '@nivo/pie'
import PropTypes from 'prop-types'
import { useValueLayer } from './ValueLayer'
import { useSubtitleLayer } from './SubtitleLayer'

function SimpleGauge ({
  width,
  height,
  marker,
  subTitle,
  cornerRadius,
  padAngle,
  valueFormat,
  valueSizeFactor,
  emptyColor,
  ...props
}) {
  const pieData = useMemo(() => {
    return [
      {
        value: marker?.value,
        color: marker?.color,
        id: 'value'
      },
      {
        value: 1 - marker?.value,
        color: emptyColor,
        id: 'remaining'
      }
    ]
  }, [emptyColor, marker])

  /** This is for displaying matching range titles instead of a numeric value */
  const _marker = useMemo(() => {
    return {
      ...marker,
      format: valueFormat
    }
  }, [marker, valueFormat])

  const valueLayer = useValueLayer(_marker, valueSizeFactor, height)
  const subTitleLayer = useSubtitleLayer(subTitle, height)

  const layers = useMemo(() => {
    return ['arcs', valueLayer, subTitleLayer]
  }, [valueLayer, subTitleLayer])

  return (
    <Pie
      layers={layers}
      width={width}
      height={height}
      data={pieData}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.9}
      cornerRadius={cornerRadius}
      padAngle={padAngle}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      {...props}
    />
  )
}

SimpleGauge.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,

  ranges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    color: PropTypes.string
  })),
  marker: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    format: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.number
  }),
  subTitle: PropTypes.string,
  valueSizeFactor: PropTypes.number,

  cornerRadius: PropTypes.number,
  padAngle: PropTypes.number,

  valueFormat: PropTypes.string,
  emptyColor: PropTypes.string
}

SimpleGauge.defaultProps = {
  subTitle: 'Probability of Success',
  cornerRadius: 0,
  padAngle: 0,
  colors: c => c.data.color,
  valueFormat: 'percentage',
  valueSizeFactor: 0.2,
  emptyColor: '#F4F4F5'
}

export default SimpleGauge
