import React, { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useWealthJourney } from '../WealthJourneyProvider'
import { useEntryTypeFilter } from '../useEntryTypeFilter'

dayjs.extend(utc)

const useMilestoneStyles = makeStyles((theme) => ({
  entryMarker: {
    '&:hover': {
      stroke: 'black',
      cursor: 'pointer'
    },
    transition: 'all 200ms ease-in-out',
    '&.__selected': {
      stroke: 'white',
      strokeWidth: '.2rem'
    }
  },
  meetingSelected: {
    fill: 'white',
    stroke: theme.palette.cloudBurst
  },
  hoverRect: {
    fill: 'transparent',
    stroke: 'transparent',
    strokeWidth: '2'
  },
  titleBackdrop: {
    fill: 'white'
  },
  title: {
    opacity: 0,
    transition: 'all 200ms ease-in-out'
  },
  titleSelected: {
    opacity: 1
  },
  line: {
    transition: 'all 200ms ease-in-out'
  }
}))

/** Some folks didn't want milestones to render outside of the chart space */
function evaluateBounds (entry, bounds = {}) {
  const boundsType = bounds?.entryBounds || 'none'
  const start = bounds?.start
  const end = bounds?.end
  const entryDate = entry?.entryDate ? dayjs.utc(entry.entryDate) : null

  if (boundsType === 'none') return true
  if (!entryDate) return false

  if (boundsType === 'bounded') {
    const s = dayjs.utc(start)
    const e = dayjs.utc(end)
    return (entryDate.isSame(s) || entryDate.isAfter(s)) && (entryDate.isSame(e) || entryDate.isBefore(e))
  }
}

export const useMilestoneLayer = (options = {}, entryTypeManualFilter = [], chartDates = { entryBounds: 'none' }) => {
  const {
    hideLine = false,
    textBackgroundColor = 'yellow',
    rotateText = true
  } = options
  const { entries, selectedEntry, setSelectedEntry, theming, entryTypes } = useWealthJourney()
  const classes = useMilestoneStyles({ theming })

  const [circleHovered, setCircleHovered] = useState(null)
  const onMouseEnter = useCallback((id) => setCircleHovered(id), [setCircleHovered])
  const onMouseLeave = useCallback(() => setCircleHovered(null), [setCircleHovered])
  const hoveredEntry = useMemo(() => {
    if (circleHovered) return circleHovered
    if (selectedEntry) return selectedEntry.entryId
    return null
  }, [selectedEntry, circleHovered])

  const _entries = useEntryTypeFilter(entries, entryTypeManualFilter)
  const filteredEntries = useMemo(() =>
    (_entries || [])
      .filter(entry => (entryTypes || []).some(et => et.entryTypeId === entry.entryTypeId))
      .filter(entry => evaluateBounds(entry, chartDates))
      .map(entry => ({
        ...entry,
        entryType: (entryTypes || []).find(et => et.entryTypeId === entry.entryTypeId)
      })),
  [_entries, entryTypes, chartDates])

  return useCallback(({ xScale, innerHeight: originalInnerHeight }) => {
    const innerHeight = hideLine ? originalInnerHeight / 2 : originalInnerHeight
    const circles = filteredEntries.map(entry => {
      // const isSelected = entry.entryId === selectedEntry?.entryId
      const showDetails = hoveredEntry === entry.entryId
      const x = xScale(dayjs(entry.entryDate))
      const title = entry?.entryJson?.title || entry?.entryText

      return {
        metadata: {
          entry
        },
        key: entry.entryId,
        x: x,
        title,
        showDetails,
        innerHeight,
        circle: {
          className: clsx(
            classes.entryMarker,
            entry?.entryType?.entryTypeCode,
            {
              __selected: showDetails
            }
          ),
          cx: x,
          cy: showDetails ? innerHeight - 20 : innerHeight,
          r: showDetails ? 16 : 8,
          fill: entry?.entryType?.color,
          stroke: entry?.entryType?.color
        },
        text: {
          className: clsx(classes.title, {
            [classes.titleSelected]: showDetails
          }),
          x: 0,
          y: 0,
          transformOrigin: `${x + 24} ${innerHeight - 17}`,
          transform: rotateText ? `translate(${x + 24}, ${innerHeight - 27}), rotate(-25)` : `translate(${x + 24}, ${innerHeight - 27})`,
          textAnchor: 'start'
        },
        textBackdrop: {
          className: clsx(classes.titleBackdrop, {
            [classes.titleBackdrop]: showDetails
          }),
          x: x - 20,
          y: innerHeight - 40,
          width: 200,
          height: 40
        },
        line: {
          className: classes.line,
          x1: x,
          x2: x,
          y1: showDetails ? innerHeight - 20 : innerHeight,
          y2: innerHeight,
          stroke: 'black',
          strokeWidth: 2
        },
        rect: {
          x: x - 10,
          y: innerHeight - 30,
          width: 20,
          height: 40,
          className: classes.hoverRect
        }
      }
    })

    return (
      <g>
        <defs>
          <filter x='-10%' y='-25%' width='1.15' height='1.5' id='svgTextBackdrop'>
            <feFlood floodColor={textBackgroundColor} result='bg' />
            <feMerge>
              <feMergeNode in='bg' />
              <feMergeNode in='SourceGraphic' />
            </feMerge>
          </filter>
          <filter width='1.5' height='1.5' id='svgDropShadow'>
            <feDropShadow dx='1.5' dy='1.5' stdDeviation='1.2' result='sh2' />
          </filter>
        </defs>
        {circles.map(c => (
          <g key={`ci_${c.key}`}>
            <line
              {...c.line}
              onMouseEnter={() => onMouseEnter(c.key)}
              onMouseLeave={onMouseLeave}
            />
            <g transform='translate(0, 0)'>
              <text filter='url(#svgTextBackdrop)' {...c.text}>{c.title}</text>
            </g>
            <rect
              onMouseEnter={() => onMouseEnter(c.key)}
              onMouseLeave={onMouseLeave}
              {...c.rect}
            />
            <circle
              filter={c.showDetails ? 'url(#svgDropShadow)' : undefined}
              {...c.circle}
              onClick={() => setSelectedEntry(c.metadata.entry)}
              onMouseEnter={() => onMouseEnter(c.key)}
              onMouseLeave={onMouseLeave}
            />
          </g>
        ))}
      </g>
    )
  }, [
    filteredEntries,
    setSelectedEntry,
    classes,
    hoveredEntry,
    onMouseLeave,
    onMouseEnter,
    hideLine,
    rotateText,
    textBackgroundColor
  ])
}
