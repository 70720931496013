import React, { useCallback, useMemo } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import dayjs from 'dayjs'
import { useResearchViewContext } from '../ResearchViewProvider'
import { useCoreChartData } from '../../../../api/coreData'
import ResearchLine from './ResearchLine'

const colors = ['#4CAF50', '#2196F3', '#FF9800', '#F44336', '#9C27B0', '#FFC107', '#00BCD4', '#795548', '#FFEB3B', '#607D8B']

const normalizeChartData = (data) => {
  const groupedData = (data?.data ?? []).reduce((acc, item) => {
    acc[item.accountId] = acc[item.accountId] || []
    acc[item.accountId].push({
      ...item,
      x: `${item.year}-${item.month}-${item.day}`,
      y: item.cumulativeReturn
    })
    return acc
  }, {})

  return Object.entries(groupedData ?? {})
    .map(([accountId, data], index) => ({
      accountId,
      color: colors[index % colors.length],
      id: accountId,
      label: `[${accountId}] ${data?.[0]?.accountName} (${data?.[0].accountNumber})`,
      data
    }))
}

export default function ResearchLineChart () {
  const { setLevelFilters, setDateRange, setSelectedMonth, levelFilters, dateRange } = useResearchViewContext()
  const lineChartQuery = useMemo(() => ({
    define: {
      cumulativeReturn: { $accessor: 'cumulativeReturn' },
      accountId: { $accessor: 'accountId' },
      accountName: { $accessor: 'accountName' },
      accountNumber: { $accessor: 'accountNumber' }
    },
    select: {
      cumulativeReturn: { $ref: 'cumulativeReturn' },
      accountId: { $ref: 'accountId' },
      accountName: { $ref: 'accountName' },
      accountNumber: { $ref: 'accountNumber' }
    },
    levelFilters: {
      ...levelFilters,
      calcType: 'timeSeries'
    },
    dateRange
  }), [levelFilters, dateRange])

  const { data, isLoading, error } = useCoreChartData(lineChartQuery, {
    mapper: (data) => normalizeChartData(data)
  })

  const seriesClick = useCallback((data) => {
    setLevelFilters(previous => ({ ...previous, accountIds: [data.serieId || data.id] }))
    try {
      if (data.data.xFormatted === undefined) return
      const pointDate = dayjs.utc(data.data.xFormatted).format('YYYY-MM-DD')
      setDateRange({ startDate: pointDate, endDate: pointDate })
      setSelectedMonth(null)
    } catch (error) {
      console.log('seriesClick error', error)
    }
  }, [setLevelFilters, setDateRange, setSelectedMonth])

  if (isLoading) {
    return (
      <Box display='flex' flexDirection='center' justifyContent='center' alignItems='center' height='400px'>
        <CircularProgress />
      </Box>
    )
  }
  if (error) {
    return (
      <Box display='flex' flexDirection='center' justifyContent='center' alignItems='center' height='200px'>
        Error {JSON.stringify(error)}
      </Box>
    )
  }

  return <ResearchLine data={data ?? []} clickSeries={seriesClick} />
}
