import { Box } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SydModal from '../../commonDesign/SydModal'
import SydDatePicker from '../../commonDesign/SydDatePicker'
import SydButton from '../../commonDesign/Button'
import { useResearchViewContext } from './ResearchViewProvider'

dayjs.extend(utc)

export function ResearchDatePickerModal (
  {
    isOpen,
    onClose
  }
) {
  const { dateRange: _dateRange, setDateRange: _setDateRange, setSelectedMonth } = useResearchViewContext()
  const [dateRange, setDateRange] = useState(_dateRange)

  useEffect(() => {
    if (isOpen) setDateRange(_dateRange)
  }, [isOpen, _dateRange])

  const handleDateChange = useCallback((key, val) => {
    const dateObj = dayjs.utc(val)

    if (!dateObj.isValid()) return

    setDateRange((prev) => ({ ...prev, [key]: dateObj.format('YYYY-MM-DD') }))
  }, [setDateRange])

  const shouldDisableDate = useCallback((key, val) => {
    if (key === 'startDate') {
      return val > new Date(dateRange.endDate)
    }
    return val < new Date(dateRange.startDate)
  }, [dateRange])

  return (
    <SydModal
      disablePortal={false}
      open={isOpen}
      onClose={onClose}
      title='Change Date Range'
    >
      <Box>
        <Box display='flex' gridGap={8} alignItems='center'>
          <SydDatePicker
            label='Start Date'
            value={dateRange.startDate}
            onChange={(val) => handleDateChange('startDate', val)}
            shouldDisableDate={(d) => shouldDisableDate('startDate', d)}
          />
          <Box>to</Box>
          <SydDatePicker
            label='End Date'
            value={dateRange.endDate}
            onChange={(val) => handleDateChange('endDate', val)}
            shouldDisableDate={(d) => shouldDisableDate('endDate', d)}
          />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between' mt={10}>
          <SydButton
            onClick={onClose}
            variant='secondary'
          >Cancel
          </SydButton>

          <SydButton
            onClick={() => {
              _setDateRange(dateRange)
              setSelectedMonth(null)
              onClose?.()
            }}
            variant='primary'
          >Change Date Range
          </SydButton>
        </Box>
      </Box>
    </SydModal>
  )
}

ResearchDatePickerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}
