import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { IconButton, makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import { useAccountSearch } from '../../../api/coreData'
import OperationalTable from '../OperationalTable'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import ThingDialog from '../Things/ThingDialog'
import FlexRow from '../../molecules/FlexRow'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles(theme => ({
  clientAccountTable: {
    '& .operational-table': {
      minWidth: '0'
    }
  }
}))

const defaultColumns = [
  { id: 'accountName', Header: 'Account Name', accessor: 'accountName' }
]

/* eslint-disable react/prop-types */
const ColumnCell = ({ cell, column, formatter, dialogRef }) => {
  let cellContent = (<>{formatter(cell.value, column.format)}</>)

  if (column.thingAccessor) {
    const thing = get(cell.row.original, column.thingAccessor)

    const onClick = () => dialogRef.current.open({
      levelTypeId: thing.levelTypeId,
      levelId: thing.levelId,
      title: thing.name,
      code: thing.code
    })

    cellContent = thing
      ? (
        <FlexRow>
          <div className='clickable' onClick={onClick}>
            {cellContent}
          </div>
          {column.thingIcon ? (
            <IconButton size='small' onClick={onClick}>
              <Icon name={column.thingIcon} />
            </IconButton>
          ) : null}
        </FlexRow>
      )
      : cellContent
  }
  return cellContent
}
/* eslint-enable react/prop-types */

const useColumns = (baseColumnConfig, dialogRef) => {
  const { formatter } = useFormattingContext()
  return useMemo(() => {
    return baseColumnConfig.map(column => {
      const result = {
        ...column
      }
      result.Cell = cell => <ColumnCell cell={cell} column={column} formatter={formatter} dialogRef={dialogRef} />

      return result
    })
  }, [formatter, baseColumnConfig, dialogRef])
}

function ClientAccountTable ({ columns = defaultColumns, defaultSort, baseQuery, ...otherProps }) {
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    return {
      ...(baseQuery || {}),
      filters: {
        assignedToClientIds: clientId,
        ...(baseQuery?.filters || {})
      }
    }
  }, [clientId, baseQuery])
  const { data, isLoading } = useAccountSearch(query, { enabled: !!query })
  const thingDialog = useRef(null)
  const _columns = useColumns(columns, thingDialog)
  const classes = useStyles()

  return (
    <OperationalTable.Wrapper className={classes.clientAccountTable}>
      <OperationalTable
        hideFooter
        itemName='Accounts'
        {...otherProps}
        mode='client'
        data={data?.data || []}
        loading={isLoading}
        columns={_columns}
        defaultSort={defaultSort}
      />
      <ThingDialog ref={thingDialog} />
    </OperationalTable.Wrapper>
  )
}

ClientAccountTable.propTypes = {
  baseQuery: PropTypes.object,
  columns: PropTypes.array,
  defaultSort: PropTypes.array,
  variant: PropTypes.oneOf(['v1', 'v2', 'blank'])
}

ClientAccountTable.defaultProps = {
  columns: defaultColumns,
  defaultSort: [
    { id: 'accountName', desc: false }
  ],
  variant: 'v2'
}

export default ClientAccountTable
