import { useCallback, useMemo, useState } from 'react'
import { postNamedQuery } from '../../../service'
import { useAppContext } from '../../../redux/slices/appContext'

export const useDownloadDocument = (documentId, contentDisposition = 'inline') => {
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)
  return {
    isLoadingDownload,
    getDownload: useCallback(async () => {
      setIsLoadingDownload(true)
      const { data: docData } = await postNamedQuery('vaultV2', 'getDocument', { documentId, includeUrl: true, contentDisposition })
      setIsLoadingDownload(false)
      window.open(docData.url, '_blank')
    }, [contentDisposition, documentId])
  }
}

export const useNormalizeLevelValues = (levels) => {
  const { firmId, clientId } = useAppContext()
  const defaultLevelIds = useMemo(() => ({
    1000000: [firmId],
    201: [clientId]
  }), [firmId, clientId])

  return useMemo(() => {
    if (!levels) {
      return [
        { levelTypeId: 1000000, levelIds: defaultLevelIds[1000000] },
        { levelTypeId: 201, levelIds: defaultLevelIds[201] }
      ]
    }

    return [levels].flat().map(({ levelTypeId, levelIds }) => ({
      levelTypeId,
      levelIds: levelIds ?? defaultLevelIds[levelTypeId]
    }))
  }, [levels, defaultLevelIds])
}

export const useGetDefaultVisibility = (visibilityOptions) => {
  const appContext = useAppContext()
  const { isAdvisor } = appContext

  return useMemo(() => {
    return visibilityOptions?.filter((option) => isAdvisor || option !== 'internal')?.[0] ?? 'private'
  }, [isAdvisor, visibilityOptions])
}
