import React, { useCallback, useImperativeHandle, useState } from 'react'
import SydModal from '../../../commonDesign/SydModal'
import ThingDialogForm from './form'

const ThingDialog = React.forwardRef(function ThingDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (config) => setDialogState({ config, open: true, processing: false })
  }), [setDialogState])

  return (
    <SydModal
      title={dialogState.config?.title ?? ''}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='sm'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<ThingDialogForm config={dialogState.config} onComplete={close} onCancel={close} />) : null}
      </div>
    </SydModal>
  )
})

ThingDialog.propTypes = {
}

export default ThingDialog
