import React, { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'
import { CSVLink } from 'react-csv'
import clsx from 'clsx'
import { useQuery } from '@tanstack/react-query'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import { useColumns } from '../columnConfig'
import config from '../../../../config'
import { useAppContext } from '../../../../redux/slices/appContext'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import Icon from '../../../atoms/Icon'
import { useResearchViewContext } from '../ResearchViewProvider'
import { useGetGroupedCoreData } from '../../../../api/coreData'
import SydButton from '../../../commonDesign/Button'
import PresentationTable from '../../PresentationTable'
import { postNamedQuery } from '../../../../service'
import useStyles from './styles'

const AccountDetailTable = ({ accountId }) => {
  const { firmId } = useAppContext()
  const { formatter } = useFormattingContext()
  const { levelFilters, dateRange } = useResearchViewContext()

  const classes = useStyles()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const { columns, defaultSort } = useColumns({
    dataType: 'accountDetail',
    formatter
  })

  const mammothBaseUrl = useMemo(() => `${config.mammothUiUrl}/batchData/${firmId}`, [firmId])
  const onRowClick = useCallback((row) => {
    const { original: { accountId } } = row
    const valueDate = dayjs.utc(row.original.maxDate).format('YYYY-MM-DD')
    window.open(`${mammothBaseUrl}/${accountId}/${valueDate}`, '_blank')
  }, [mammothBaseUrl])

  const downloadFileName = useMemo(() => {
    if (levelFilters.assetIds) {
      return `asset_${levelFilters.assetIds.join('_')}_account_${accountId}_details`
    }
    if (levelFilters?.accountIds?.length) {
      return `accounts_${levelFilters.accountIds.join('_')}_${accountId}_details`
    }
    return `account_${accountId}_details`
  }, [accountId, levelFilters.accountIds, levelFilters.assetIds])

  const { data, isLoading, error } = useQuery({
    queryKey: ['researchView', dateRange, levelFilters, accountId],
    queryFn: async () => {
      const [
        accountLevelData,
        assetLevelData
      ] = await Promise.all([
        postNamedQuery('coreData', 'getGroupedCoreData', {
          levelFilters: {
            ...levelFilters,
            accountIds: [accountId],
            levelTypes: ['account']
          },
          dateRange
        }),
        postNamedQuery('coreData', 'getGroupedCoreData', {
          levelFilters: {
            ...levelFilters,
            accountIds: [accountId],
            levelTypes: ['account', 'asset']
          },
          dateRange
        })
      ])

      return {
        accountLevelData: accountLevelData.data,
        assetLevelData: assetLevelData.data
      }
    }
  })

  const tableData = useMemo(() => {
    if (!data) return []
    const { accountLevelData, assetLevelData } = data
    return accountLevelData.map((account) => {
      const assetValueDates = assetLevelData
        .filter((asset) => asset.maxDate === account.maxDate)
        .map(a => ({ ...a, _next: null, isAssetLevel: true }))
      return {
        ...account,
        expanded: isExpanded,
        subRows: assetValueDates
      }
    })
  }, [data, isExpanded])

  const csvData = useMemo(() => {
    if (!tableData) return []
    return tableData.flatMap(({ subRows, ...account }) => ([account, ...subRows]))
  }, [tableData])

  if (error) return <div> Error {JSON.stringify(error)} </div>

  return (
    <>
      <div className={classes.header}>
        <Text variant={TEXT_VARIANTS.h3}>
          Account <div className={classes.label}>{accountId}</div>
        </Text>
        <Box display='flex' gridGap={6}>
          {!!csvData?.length && (
            <CSVLink data={csvData} filename={downloadFileName ?? `account_${accountId}_details`}>
              <SydButton variant='primary' icon={ICON_NAMES.download} size='sm'>
                Download CSV
              </SydButton>
            </CSVLink>
          )}
          <SydButton
            size='sm'
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Hide Position Day Values' : 'Show Position Day Values'}
          </SydButton>
          <SydButton
            size='sm'
            className={clsx([classes.collapseButton], { [classes.collapseButtonActive]: !isCollapsed })}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <Icon name={ICON_NAMES.chevronDown} customSize={18} />
          </SydButton>
        </Box>
      </div>

      {!isCollapsed && (
        <PresentationTable
          key={`detailTable${accountId}`}
          columns={columns}
          data={tableData ?? []}
          loading={isLoading}
          defaultSort={defaultSort}
          onRowClick={onRowClick}
          sortable
        />
      )}
    </>
  )
}

AccountDetailTable.propTypes = {
  accountId: PropTypes.number
}

const AccountDetailTables = () => {
  const { levelFilters, dateRange } = useResearchViewContext()
  const classes = useStyles()

  const { data: accounts, isLoading, error } = useGetGroupedCoreData({
    levelFilters: {
      ...levelFilters,
      levelTypes: ['account'],
      dateType: 'all'
    },
    dateRange
  })

  if (error) return <div> Error {JSON.stringify(error)} </div>
  if (isLoading) {
    return (
      <Box display='flex' flexDirection='center' justifyContent='center' alignItems='center' height='150px'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>
      {accounts?.length > 1 && (
        <div className={classes.sectionHeader}>
          <Text variant={TEXT_VARIANTS.h3}>
            {accounts?.length} Accounts
          </Text>
        </div>
      )}
      {accounts?.map(({ accountId }) => (
        <Box key={`accountDiv${accountId}`} mb={1}>
          <AccountDetailTable accountId={accountId} />
        </Box>
      ))}
    </div>
  )
}

export default AccountDetailTables
